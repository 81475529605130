// src/useTimer.ts
import { useAppSelector } from "Redux/App/hooks";
import { useState, useEffect, useRef, useCallback } from "react";

interface UseTimerReturn {
  time: string;
  isActive: boolean;
  start: () => void;
  stop: () => void;
  reset: () => void;
  setTime: (timeString: string) => void;
}

const useTimerSecond = (initialTime: string): UseTimerReturn => {
  const parseTime = (timeString: string): number => {
    const [minutes, seconds] = timeString.split(":").map(Number);
    return minutes * 60 + (seconds || 0);
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const [time, setTimeState] = useState<number>(parseTime(initialTime));
  const [isActive, setIsActive] = useState<boolean>(false);
  const timerRef = useRef<number | undefined>(undefined);

  const start = useCallback(() => {
    setIsActive(true);
  }, []);

  const stop = useCallback(() => {
    setIsActive(false);
  }, []);

  const reset = useCallback(() => {
    setIsActive(false);
    setTimeState(parseTime(initialTime));
  }, [initialTime]);

  const setTime = useCallback((timeString: string) => {
    setTimeState(parseTime(timeString));
  }, []);

  const isAllQuestionAttempt = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );

  useEffect(() => {
    if (isActive && !isAllQuestionAttempt && time > 0) {
      timerRef.current = window.setInterval(() => {
        setTimeState((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0) {
      setIsActive(false);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isActive, isAllQuestionAttempt, time]);

  return { time: formatTime(time), isActive, start, stop, reset, setTime };
};

export default useTimerSecond;
