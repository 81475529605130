import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";

interface fetchPageWalkThrough {
  user_id: string | null;
  page_name: string;
  is_mobile: number;
}
interface fetchReadNotification {
  notification_id: number;
  is_read: number;
}
interface fetchDeletedNotification {
  notification_id: number;
  is_remove: number;
}
interface updatePageWalkThrough {
  user_id: string | null;
  page_name: string;
  user_guide_name: string;
  is_read: number;
  is_mobile: number;
}

export const FetchNotification = createAsyncThunk(
  "Notification/userNotification",
  async (data: { user_id: string }) => {
    try {
      const res = await axiosClient.get(
        `get-notifications?user_id=${data.user_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const FetchSearchedNotification = createAsyncThunk(
  "Notification/searchedNotification",
  async (data: { notification_id: string | null; query: string }) => {
    try {
      const res = await axiosClient.get(
        `get-notifications?user_id=${data.notification_id}&search_string=${data.query}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchReadNotification = createAsyncThunk(
  "Notification/readNotification",
  async (data: fetchReadNotification) => {
    try {
      const res = await axiosClient.post("is-read", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchDeletedNotification = createAsyncThunk(
  "Notification/deletedNotification",
  async (data: fetchDeletedNotification) => {
    try {
      const res = await axiosClient.post("is-remove", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const UpdatePageWalkThrough = createAsyncThunk(
  "Notification/updateWalkThrough",
  async (data: updatePageWalkThrough) => {
    try {
      const res = await axiosClient.post("update-page-walk-through", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const FetchPageWalkThrough = createAsyncThunk(
  "Notification/pageWalkThrough",
  async (data: fetchPageWalkThrough) => {
    try {
      const res = await axiosClient.get(
        `get-page-walk-through?user_id=${data.user_id}&page_name=${data.page_name}&is_mobile=${data.is_mobile}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

interface initialStage {
  isLoading: boolean;
  notification: any[];
  error: any;
  searchTerm: string;
  deleteNotification: any[];
  readNotification: any[];
  updatePopupWalkThrough: any[];
  course_data: any[];
  user_data: any;
}
const initialState: initialStage = {
  isLoading: true,
  notification: [],
  error: null,
  searchTerm: "",
  deleteNotification: [],
  readNotification: [],
  updatePopupWalkThrough: [],
  course_data: [],
  user_data: {},
};
const UserNotification = createSlice({
  name: "notification",
  initialState,
  reducers: {
    deleteOneNotification: (state, action) => {
      const notification = state.notification.findIndex(
        (item) => item.id === action.payload
      );
      if (notification !== -1) {
        state.notification.splice(notification, 1);
      }
    },
    readOneNotification: (state, action) => {
      const updateNotification = state.notification.map((notification) => {
        if (notification.id === action.payload) {
          return { ...notification, is_read: 1 };
        }
        return notification;
      });
      return {
        ...state,
        notification: updateNotification,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(FetchNotification.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
          notification: [],
        };
      })
      .addCase(FetchNotification.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          notification: action.payload?.data,
          user_data: action.payload?.user_data,
          course_data: action.payload?.course_data,
        };
      })
      .addCase(FetchNotification.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(FetchSearchedNotification.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchSearchedNotification.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          searchTerm: action.payload?.data,
          notification: action.payload?.data,
        };
      })
      .addCase(FetchSearchedNotification.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(FetchDeletedNotification.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchDeletedNotification.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          deleteNotification: action.payload,
        };
      })
      .addCase(FetchDeletedNotification.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(FetchReadNotification.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(
        FetchReadNotification.fulfilled,
        (state, action: PayloadAction<any>) => {
          return {
            ...state,
            isLoading: false,
            readNotification: action.payload,
          };
        }
      )
      .addCase(FetchReadNotification.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(UpdatePageWalkThrough.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(UpdatePageWalkThrough.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          // updatePopupWalkThrough: action.payload.data,
        };
      })
      .addCase(UpdatePageWalkThrough.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(FetchPageWalkThrough.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchPageWalkThrough.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          updatePopupWalkThrough: action.payload.data,
        };
      })
      .addCase(FetchPageWalkThrough.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      });
  },
});

export const { deleteOneNotification, readOneNotification } =
  UserNotification.actions;
export default UserNotification.reducer;
