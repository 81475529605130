import { Box } from "@mui/system";
import { Divider } from "@mui/material";
import { Copyright } from "./ImageTemplate";

interface FooterProps {
  isFooterMargin?: boolean;
}

function Footer({ isFooterMargin }: FooterProps) {
  return (
    <>
      <Box
        className="page-footer"
        sx={{
          backgroundColor: "#fff",
          // position: { xs: "relative", sm: "fixed" },
          // left: "0",
          // right: "0",
          // bottom: "0",
          width: "100%",
          zIndex: "9",
          marginBottom: isFooterMargin ? "70px" : "0px",
        }}
      >
        <Divider sx={{ marginTop: "1px" }} />
        <Copyright
          sx={{
            display: { xs: "block", sm: "flex" },
            alignItems: "center",
            justifyContent: "space-between",
            py: "10px",
            minHeight: "40px",
          }}
        />
      </Box>
    </>
  );
}

export default Footer;
