import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  FetchCourses,
  updateCourseLabel,
  updateSelectedCourseId,
} from "Redux/feature/BoardExam";
import { useLocation, useParams } from "react-router";
import Creatable from "react-select/creatable";
import { FormattedMessage } from "react-intl";
import { FetchAtomicAndContainerData } from "Redux/feature/StudentHome";
import { useMediaQuery } from "@mui/material";
import { clearCookie, getCookie, setCookie } from "views/common/cookieUtils";

const CourseList: React.FC = () => {
  const user_id = getCookie("id");
  const userType = getCookie("user_type");

  const location = useLocation();
  const { id } = useParams();
  const portalTargetRef = useRef(null);
  const dispatch = useAppDispatch();
  const courseDataTeacher: any = useAppSelector(
    (state) => state.BoardExam.course
  );
  const courseDataStudent: any = useAppSelector(
    (state) => state.UserNotification.course_data
  );
  const [defaultValue, setDefaultValue] = useState<any>(null);
  const isMobile = useMediaQuery("(max-width:767px)");
  const defaultCourseId = getCookie("default_course_id");

  const atomicUnitData = useAppSelector(
    (state) => state.StudentsHomeData.atomicUnitsData
  );
  let selectedCourse1: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  const courseData: any =
    userType == "Student" ? courseDataStudent : courseDataTeacher;
  const generateCourseArr = (data: any) => {
    return data.map((value: any, index: number) => ({
      value: value?.courses?.id,
      label: value?.courses?.name,
    }));
  };

  const [courseArr, setCourseArr] = useState<any[]>([]);

  useEffect(() => {
    if (!defaultCourseId) {
      setCookie("default_course_id", courseData[0]?.courses?.id, 4, "h");
      dispatch(updateSelectedCourseId(courseData[0]?.courses?.id));
      dispatch(updateCourseLabel(courseData[0]?.courses?.className));
    }
  }, [courseData]);

  useEffect(() => {
    setCourseArr(generateCourseArr(courseData));
  }, [courseData]);

  const courseId = courseArr[0]?.value;

  useEffect(() => {
    if (userType == "Teacher" && user_id) {
      dispatch(
        FetchCourses({
          teacher_id: user_id,
        })
      );
    }
  }, [userType == "Teacher" && user_id]);

  useEffect(() => {
    dispatch(updateSelectedCourseId(courseId));
  }, [courseId]);

  useEffect(() => {
    let idToSend: any = "";

    if (
      location.pathname === `/home/session-management/${id}` ||
      (location.pathname === `/home/session-management/add-assignment/${id}` &&
        typeof id !== "undefined")
    ) {
      idToSend = id;
    } else {
      idToSend = user_id;
    }

    if (
      (selectedCourse1 > 0 &&
        location.pathname === `/home/session-management/${id}`) ||
      (selectedCourse1 > 0 &&
        location.pathname === `/home/session-management/add-assignment/${id}`)
    ) {
      dispatch(
        FetchAtomicAndContainerData({
          id: idToSend,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse1,
        })
      ).then((resp: any) => {});
    }
  }, [defaultCourseId]);

  useEffect(() => {
    if (!defaultCourseId && courseArr.length > 0) {
      setDefaultValue(courseArr[0]);
      dispatch(updateCourseLabel(courseArr[0].label));
    } else {
      if (!defaultCourseId) {
        // localStorage.removeItem("default_course_id");
        clearCookie("default_course_id");
        setDefaultValue(undefined);
        dispatch(updateCourseLabel(undefined));
      } else {
        const courseId = parseInt(defaultCourseId, 10);
        const course = courseArr.find((course) => course.value === courseId);
        const courseIndex = courseArr.findIndex(
          (course) => course.value === courseId
        );

        setDefaultValue(courseArr[courseIndex]);
        dispatch(updateCourseLabel(course?.label));
      }
    }
  }, [courseArr, defaultCourseId]);

  const handleChange = (val: any) => {
    setCookie("default_course_id", val.value, 4, "h");

    if (val) {
      if (location.pathname === "/student-home" && user_id) {
        dispatch(
          FetchAtomicAndContainerData({
            id: user_id,
            course_id: val.value,
          })
        );
      }
      setDefaultValue(val);
      dispatch(updateSelectedCourseId(val.value));
      dispatch(updateCourseLabel(val.label));
    } else {
      dispatch(updateSelectedCourseId(id));
      dispatch(updateCourseLabel(courseArr[0]));

      dispatch(updateSelectedCourseId(courseId));
    }
  };
  return (
    <>
      {isMobile ? (
        <Creatable
          menuPortalTarget={portalTargetRef.current}
          menuPosition="absolute"
          placeholder={<FormattedMessage id="course-title-blank" />}
          className="dropDownMenu headerDropdown course-dropdown custom-select-control"
          options={courseArr}
          onChange={handleChange}
          value={defaultValue}
          // isDisabled={
          //   location.pathname === "/student-home" && atomicUnitData.length === 0
          // }
          isSearchable={false}
          // styles={{
          //   menu: (base) => ({
          //     ...base,
          //     // height:
          //     //   window.location.pathname === "/teacher-home"
          //     //     ? "190px"
          //     //     : "190px",
          //     maxHeight: '190px',
          //     overflowY: "auto",
          //   }),
          // }}

          styles={{
            container: (base) => ({
              ...base,
              overflow: "visible",
            }),
            menu: (base) => ({
              ...base,
              maxHeight: "190px",
              overflowY: "auto",
            }),
            menuList: (base) => ({
              ...base,
              maxHeight: "190px",
              overflowY: "auto",
            }),
          }}
        />
      ) : (
        <Creatable
          menuPortalTarget={document.body}
          menuPosition="absolute"
          placeholder={<FormattedMessage id="course-title-blank" />}
          className="dropDownMenu headerDropdown course-dropdown custom-select-control"
          options={courseArr}
          onChange={handleChange}
          value={defaultValue}
          // isDisabled={
          //   location.pathname === "/student-home" && atomicUnitData.length === 0
          // }
          isSearchable={false}
        />
      )}
    </>
  );
};

export default CourseList;
