import { useEffect, useState, FormEvent } from "react";
import {
  Button,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  Link,
  Alert,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import { FetchClientLogin, updateWorkerId } from "Redux/feature/UserAuth";
import { removeAllCookies, setCookie } from "views/common/cookieUtils";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [workerId, setWorkerId] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const step = useAppSelector(
    (state: any) => state.BoardingSlice.personalDetails
  );

  useEffect(() => {
    if (step.steps_done === 6 && location.pathname === "/on-boarding") {
      navigate("/student-home");
    }
  }, [step, location.pathname, navigate]);

  useEffect(() => {
    dispatch(updateWorkerId(workerId));
  }, [workerId, dispatch]);

  const responseMessage = useAppSelector(
    (state: any) => state.UserAuth.message
  );

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     localStorage.clear();
  //     removeAllCookies();
  //     window.location.reload();
  //   }
  //   // dispatch(ResetStudentHome());
  // }, [isLoggedIn]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    setButtonDisable(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // Dispatch login action
    dispatch(
      FetchClientLogin({
        user_id: workerId,
        password: data.get("password") as string,
      })
    ).then((res: any) => {
      setButtonDisable(false);

      if (res.payload.status) {
        // Clear cookies first before setting new ones
        clearCookie("id");
        clearCookie("accessToken");
        clearCookie("user_type");
        clearCookie("user_name");
        clearCookie("product_category_id");

        // Set new cookie values
        setCookie("id", res.payload.data.id, 4, "h"); // Expires in 1 mour
        setCookie("accessToken", res.payload.token, 4, "h");
        setCookie("user_type", res.payload.data.user_type, 4, "h");
        setCookie("user_name", res.payload.data.full_name, 4, "h");
        setCookie(
          "product_category_id",
          res.payload.data.product_category_id,
          4,
          "h"
        );

        // Navigate based on user type and onboarding status
        res.payload.data.user_type === "Teacher"
          ? navigate("/teacher-home")
          : res.payload.data.is_onboarding_redirect === 1
          ? navigate("/on-boarding")
          : navigate("/student-home");
      } else {
        setShowAlert(true);
      }

      // Hide the alert after 4 seconds
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    });
  };

  // Function to clear cookies
  const clearCookie = (name: string) => {
    document.cookie = `${name}=; Max-Age=-99999999;`;
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          width={{ xs: "100%", sm: "394px" }}
          sx={{
            mx: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: "15px",
            pb: "35px",
          }}
        >
          <Typography
            sx={{ fontSize: { md: "32px", xs: "24px" }, fontWeight: 700 }}
            className="login-title"
          >
            <FormattedMessage id="login-title" />
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1, width: "100%" }}
          >
            <Typography
              variant="subtitle1"
              textAlign={{ xs: "center", md: "right" }}
            >
              <FormattedMessage id="sample-text" />
            </Typography>

            <InputLabel
              htmlFor="userId"
              sx={{ marginTop: "40px", marginBottom: "5px" }}
            >
              <FormattedMessage id="userName" />
            </InputLabel>
            <TextField
              margin="none"
              required
              fullWidth
              id="userId"
              name="userId"
              value={workerId}
              onChange={(e) => {
                setWorkerId(e.target.value);
              }}
              type="text"
              size="small"
              sx={{ fontSize: "16px", height: "38px" }}
            />

            <InputLabel
              sx={{ marginTop: "25px", marginBottom: "5px" }}
              htmlFor="password"
            >
              <FormattedMessage id="password" />
            </InputLabel>

            <FormControl sx={{ mr: 0, width: "100%" }} variant="outlined">
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_17054_826)">
                            <path
                              d="M14.12 14.12C13.8454 14.4147 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.481 9.80385 14.1962C9.51897 13.9113 9.29439 13.5719 9.14351 13.1984C8.99262 12.8248 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2218 9.18488 10.8538C9.34884 10.4859 9.58525 10.1547 9.88 9.88M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.6819 3.96914 7.65661 6.06 6.06L17.94 17.94ZM9.9 4.24C10.5883 4.07888 11.2931 3.99834 12 4C19 4 23 12 23 12C22.393 13.1356 21.6691 14.2047 20.84 15.19L9.9 4.24Z"
                              stroke="#AEB5BC"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M1 1L23 23"
                              stroke="#AEB5BC"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_17054_826">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                            stroke="#AEB5BC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                            stroke="#AEB5BC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                margin="none"
                required
                fullWidth
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                size="small"
                placeholder="הכנסת סיסמה"
                sx={{ fontSize: "16px" }}
                aria-describedby="component-error-text"
              />
            </FormControl>
            {showAlert && (
              <Alert
                className="removeIconLessonBooksAlert"
                sx={{
                  width: "auto",
                  height: "auto",
                  color: "#E74C3C",
                  fontWeight: 600,
                  fontSize: "16px",
                  padding: "0px",
                  backgroundColor: "transparent",
                }}
              >
                {responseMessage}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ marginTop: "40px", height: "40px", fontSize: "18px" }}
              disabled={password === "" || buttonDisable}
            >
              <FormattedMessage id="login-system" />
            </Button>
          </Box>
          {workerId === "" ? (
            <Typography
              color="text.secondary"
              variant="body2"
              sx={{
                marginTop: "17px",
                fontSize: "18px",
                cursor: "not-allowed",
              }}
            >
              <FormattedMessage id="forgot-password" />
            </Typography>
          ) : (
            <Link
              color="primary"
              onClick={handleForgotPassword}
              variant="body2"
              sx={{
                marginTop: "17px",
                fontSize: "18px",
                cursor: "pointer",
              }}
            >
              <FormattedMessage id="forgot-password" />
            </Link>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Login;
