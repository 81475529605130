import { Check, Close } from "@mui/icons-material";
import {
  Grid,
  RadioGroup,
  ToggleButton,
  Typography,
  styled,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import parse from "html-react-parser";
import Joyride, { CallBackProps, Step } from "react-joyride";
import {
  FetchPageWalkThrough,
  UpdatePageWalkThrough,
} from "Redux/feature/NotificationSlice";
import { useWirisParser } from "hooks/useWiris";
import Loader from "views/common/loader";
import { setMathFlag } from "Redux/feature/Questions";
import { getCookie } from "views/common/cookieUtils";

type Props = {
  options: any[];
  handleSelection: Function;
  optionId?: number;
  horizontal?: number;
  englishLayout?: boolean;
};

type QuestionOptionProps = {
  isAllQuestionAttempt: boolean;
};

const QuestionOption = styled(ToggleButton)<QuestionOptionProps>(
  ({ theme, isAllQuestionAttempt }) => ({
    borderRadius: 50,
    width: "30px",
    height: "30px",
    fontSize: "14px",
    marginLeft: "5px",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    transition: "all 0.1s ease",
    "&.Mui-selected": {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
    ...(isAllQuestionAttempt === false && {
      "&:hover": {
        color: "white",
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "&:focus": {
        color: "white",
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
    }),
    ...(isAllQuestionAttempt && {
      pointerEvents: "none",
    }),
  })
);

function QuestionAttemptAndCheck({
  options,
  handleSelection,
  horizontal,
  optionId,
  englishLayout,
}: Props) {
  const userTypee = getCookie("user_type");

  const dispatch = useAppDispatch();
  const questionRef = useRef(null);

  const isAllQuestionAttempt = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );
  const fetchAllSubmittedQuestionAnswer: any = useAppSelector(
    (state) => state.Questions.fetchAllSubmittedQuestions
  );

  const rederPreview: any = useAppSelector(
    (state) => state.Questions.renderPreview
  );

  const [correctAnswer, setCorrectAnswer] = useState<any>([]);
  const isMobile = useMediaQuery("(max-width:767px)");
  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );
  const [searchParams, setSerchParams] = useSearchParams();
  let user_id = getCookie("id");
  let containerId = searchParams.get("container_type_id")
    ? searchParams.get("container_type_id")
    : 0;

  const [steps, setSteps] = useState<Step[]>([]);

  const renderPreview: any = useAppSelector(
    (state) => state.Questions.renderPreview
  );
  const mathFlag: boolean = useAppSelector((state) => state.Questions.mathFlag);

  useWirisParser(questionRef);

  useEffect(() => {
    if (isMobile && renderPreview === false && activeQuestion == 0 && user_id) {
      dispatch(
        FetchPageWalkThrough({
          user_id: user_id,
          page_name: "question-page-mobile",
          is_mobile: 1,
        })
      );
    }

    if (isMobile) {
      const studentSteps: Step[] = [
        {
          target: ".question-option-mobile-1",
          content:
            "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים.",
          offset: 0,
          disableBeacon: true,
        },
        {
          target: ".question-option-mobile-2",
          content:
            "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים.",
          offset: 0,
          disableBeacon: true,
        },
        {
          target: ".question-option-mobile-3",
          content:
            "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים.",
          offset: 0,
          disableBeacon: true,
        },
        {
          target: ".question-option-mobile-4",
          content:
            "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים.",
          offset: 0,
          disableBeacon: true,
        },
        {
          target: ".question-option-mobile-5",
          content:
            "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים.",
          offset: 0,
          disableBeacon: true,
        },
      ];
      setSteps(studentSteps);
    }
  }, [isMobile, renderPreview, activeQuestion]);
  useEffect(() => {
    const hasMathTag = (text: string) => {
      const mathTagRegex = /<math/;
      return mathTagRegex.test(text);
    };

    const shouldShowLoader = () => {
      for (const option of options) {
        if (hasMathTag(option.text)) {
          return true;
        }
      }
      return false;
    };

    const showLoader = shouldShowLoader();
    dispatch(setMathFlag(showLoader));
  }, [options]);

  useEffect(() => {
    if (isAllQuestionAttempt === true) {
      handleRadioCheck();
    }
    if (
      user_id &&
      isMobile === false &&
      renderPreview === false &&
      activeQuestion == 0
    ) {
      dispatch(
        FetchPageWalkThrough({
          user_id: user_id,
          page_name: "question-page",
          is_mobile: 0,
        })
      );
    }
  }, [user_id, isMobile, renderPreview, activeQuestion]);

  useEffect(() => {
    if (isAllQuestionAttempt === true) {
      handleRadioCheck();
    }
  }, [fetchAllSubmittedQuestionAnswer]);

  const addClassToTags = (node: any) => {
    if (node.type === "tag") {
      if (node.attribs && node.attribs.class) {
        node.attribs.class += " englishStyles";
      } else {
        node.attribs = {
          ...node.attribs,
          class: "englishStyles",
        };
      }
    }
    return node;
  };
  const optionCheck = (optionText: any) => {
    return parse(optionText, {
      replace: (node) => addClassToTags(node),
    });
  };

  const handleRadioCheck = () => {
    const arrayOfIds = options.map((obj) => obj.id.toString());
    let ans: any = [];
    if (containerId == 5) {
      fetchAllSubmittedQuestionAnswer?.forEach((e: any) => {
        if (arrayOfIds.includes(e.answer) && e.is_skipped_question == 0) {
          ans.push(e);
        }
      });
    } else {
      !rederPreview &&
        fetchAllSubmittedQuestionAnswer?.forEach((e: any) => {
          if (arrayOfIds.includes(e.answer)) {
            ans.push(e);
          }
        });
    }
    setCorrectAnswer(ans);
  };

  const updatePageWalkData = useAppSelector(
    (state) => state.UserNotification.updatePopupWalkThrough
  );
  const [activeIndex, setActiveIndex] = useState<any>([]);

  useEffect(() => {
    const currentIndex: any = updatePageWalkData.findIndex(
      (item) => item.is_read === 0
    );
    if (currentIndex !== -1) {
      setActiveIndex((prevIndex: any) =>
        prevIndex !== currentIndex ? currentIndex : prevIndex
      );
    }
  }, [updatePageWalkData]);

  useEffect(() => {
    if (userTypee === "Student") {
      if (isMobile === false) {
        const studentSteps: Step[] = [
          {
            target: ".question-option-1",
            content:
              "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים.",
            offset: 0,
            disableBeacon: true,
          },
          {
            target: ".question-option-2",
            content:
              "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים.",
            offset: 0,
          },
          {
            target: ".question-option-3",
            content:
              "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים.",
            offset: 0,
          },
          {
            target: ".question-option-4",
            content:
              "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים.",
            offset: 0,
          },
          {
            target: ".question-option-5",
            content:
              "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים.",
            offset: 0,
          },
        ];
        setSteps(studentSteps);
      }
    }
  }, [userTypee]);

  const [run, setRun] = useState(false);

  const handleTourEnd = (data: CallBackProps) => {
    const { action, index, status, type, lifecycle } = data;
    if (type === "step:after") {
      const classNameWithoutDot = (data.step.target as string).substring(1);

      if (activeQuestion == 0) {
        if (isMobile === false && renderPreview === false) {
          dispatch(
            UpdatePageWalkThrough({
              user_id: user_id ? user_id : null,
              page_name: "question-page",
              user_guide_name: classNameWithoutDot,
              is_read: 1,
              is_mobile: 0,
            })
          ).then((resp) => {
            if (resp.payload.code === 200) {
              setActiveIndex(activeIndex + 1);
            }
          });
        } else {
          dispatch(
            UpdatePageWalkThrough({
              user_id: user_id ? user_id : null,
              page_name: "question-page-mobile",
              user_guide_name: classNameWithoutDot,
              is_read: 1,
              is_mobile: 1,
            })
          ).then((resp) => {
            if (resp.payload.code === 200) {
              setActiveIndex(activeIndex + 1);
            }
          });
        }
      }
    }
    if (data.status === "finished") {
      setRun(false);
    }
  };
  return (
    <Box ref={questionRef}>
      {Array.isArray(updatePageWalkData) &&
        userTypee === "Student" &&
        updatePageWalkData?.map(
          (guide: any, index: number) =>
            index === activeIndex &&
            guide?.is_read === 0 &&
            renderPreview === false && (
              <Joyride
                key={index}
                continuous
                steps={steps}
                callback={handleTourEnd}
                showSkipButton
                showProgress
                hideBackButton={true}
                disableOverlayClose={true}
                scrollOffset={50}
                stepIndex={activeIndex}
                locale={{
                  last: "סיים",
                  next: "המשך",
                  skip: "סיים",
                }}
                styles={{
                  buttonNext: {
                    background: "#7A49B9",
                    padding: "10px",
                    outline: "0",
                  },
                  options: {
                    overlayColor: "rgba(0, 0, 0, 0.4)",
                    primaryColor: "#7A49B9",
                    textColor: "#4A5056",
                  },
                }}
              />
            )
        )}
      {mathFlag && <Loader />}
      <RadioGroup
        name="text-radio"
        sx={{
          display: { xs: "block", sm: "block", md: "flex", lg: "flex" },

          direction:
            horizontal === 1 || horizontal === 0 || englishLayout === true
              ? "ltr"
              : "rtl",
          flexDirection: englishLayout == true ? "row" : "row-reverse",
        }}
      >
        <>
          {options.map((item: any, index: any) => {
            const correctAnswerId =
              containerId === "5"
                ? correctAnswer.filter(
                    (object: any) => object.question_id === item.question_id
                  )
                : correctAnswer;
            return (
              <>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    flexDirection:
                      englishLayout === true ? "row-reverse" : "row",
                    width:
                      horizontal === 1 ? { xs: "100%", md: "25%" } : "100%",
                    marginBottom: horizontal === 1 ? "5px" : "10px",
                  }}
                >
                  <div
                    style={{
                      display:
                        isAllQuestionAttempt === false ? "block" : "none",
                    }}
                  >
                    {horizontal === 1 ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection:
                              englishLayout === true ? "row-reverse" : "row",
                          }}
                        >
                          <QuestionOption
                            value={item.id}
                            onClick={() => handleSelection(item.id)}
                            selected={optionId == item.id}
                            sx={{}}
                            isAllQuestionAttempt={isAllQuestionAttempt}
                          >
                            {index + 1}
                          </QuestionOption>
                          <Typography>
                            {englishLayout === true ? (
                              <div style={{ marginLeft: "10px" }}>
                                {optionCheck(item.text)}
                              </div>
                            ) : (
                              <div
                                className={
                                  item &&
                                  item.text &&
                                  typeof item.text === "string" &&
                                  item.text.includes("<math") &&
                                  !rederPreview
                                    ? "custom-math"
                                    : ""
                                }
                                dangerouslySetInnerHTML={{
                                  __html: item.text,
                                }}
                              ></div>
                            )}
                          </Typography>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection:
                              englishLayout === true ? "row-reverse" : "row",
                          }}
                        >
                          <QuestionOption
                            className={`${
                              activeQuestion == 0
                                ? "question-option-1 question-option-mobile-1"
                                : ""
                            }`}
                            value={item.id}
                            onClick={() => handleSelection(item.id)}
                            selected={optionId == item.id}
                            isAllQuestionAttempt={isAllQuestionAttempt}
                          >
                            {index + 1}
                          </QuestionOption>
                          <Typography>
                            {englishLayout === true ? (
                              <>
                                <div
                                  style={{
                                    marginLeft: "10px",
                                  }}
                                >
                                  {optionCheck(item.text)}
                                </div>
                              </>
                            ) : (
                              <div
                                className={
                                  item.text.includes("<math") && !rederPreview
                                    ? "custom-math"
                                    : ""
                                }
                                dangerouslySetInnerHTML={{
                                  __html: item.text,
                                }}
                              ></div>
                            )}
                          </Typography>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display:
                        isAllQuestionAttempt === false ? "none" : "inline-flex",
                      flexDirection:
                        englishLayout === true ? "row-reverse" : "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        marginLeft: englishLayout === true ? "8px" : "10px",
                      }}
                    >
                      {isAllQuestionAttempt === true ? (
                        (item.is_correct === 0 && item.id === optionId) ||
                        (item.is_correct === 0 &&
                          correctAnswerId[0]?.answer == item.id) ? (
                          <Close
                            color="error"
                            sx={{
                              height: "15px",
                              width: "15px",
                            }}
                          />
                        ) : item.is_correct === 1 ? (
                          <Check
                            color="success"
                            sx={{
                              height: "15px",
                              width: "15px",
                            }}
                          />
                        ) : (
                          <Close
                            color="success"
                            sx={{
                              height: "15px",
                              width: "15px",
                              opacity: "0",
                            }}
                          />
                        )
                      ) : (
                        <Close
                          color="success"
                          sx={{
                            height: "15px",
                            width: "15px",
                            opacity: "0",
                          }}
                        />
                      )}
                    </Box>
                    <QuestionOption
                      value={item.id}
                      onClick={() => handleSelection(item.id)}
                      selected={correctAnswerId[0]?.answer == item.id}
                      isAllQuestionAttempt={isAllQuestionAttempt}
                    >
                      {index + 1}
                    </QuestionOption>
                    <Typography>
                      {englishLayout === true ? (
                        <>
                          <div
                            style={{
                              marginLeft: "6px",
                              marginRight: "8px",
                            }}
                          >
                            {optionCheck(item.text)}
                          </div>
                        </>
                      ) : (
                        <div
                          className={
                            typeof item.text === "string"
                              ? item.text.includes("<math") && !rederPreview
                                ? "custom-math"
                                : ""
                              : ""
                          }
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        ></div>
                      )}
                    </Typography>
                  </div>
                </Grid>
              </>
            );
          })}
        </>
      </RadioGroup>
    </Box>
  );
}

export default QuestionAttemptAndCheck;
