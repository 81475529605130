
import React from 'react'
import {Box } from "@mui/material";

interface Question {
  id: any;
  question_id: number;
  answer: string | null;
  is_skipped_question: number;
}


type Props = {
    questionId: number;
    questions:Question[];
    handleOptionClick: (questionId: number, option: string) => void;
  };

function RenderOptions({questionId,questions,handleOptionClick}: Props) {
    const options = ["1", "2", "3", "4"];

    return (
        <Box
        sx={{
          display: 'flex',
          gap: '20px',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom:"25px"
        }}
        >
          {options.map((option) => (
            <span
              key={option}
              style={{
                height: "24px",
                width: "24px",
                border: "1px solid #6A3FA1",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor:
                  questions.find((q) => q.question_id === questionId)?.answer ===
                  option
                    ? "#7A49B9"
                    : "transparent",
                color:
                  questions.find((q) => q.question_id === questionId)?.answer ===
                  option
                    ? "white"
                    : "#7A49B9",
                  
              }}
              onClick={() => handleOptionClick(questionId, option)}
            >
              {option}
            </span>
          ))}
        </Box>
      );
}

export default RenderOptions