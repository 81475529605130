import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import UserAuth from "../feature/UserAuth";
import Questions from "Redux/feature/Questions";
import UserNotification from "../feature/NotificationSlice";
import MeetingsSubjects from "../feature/LibrarySlice";
import LessonsBook from "../feature/LessonsSlice";
import StudentsHomeData from "../feature/StudentHome";
import LibrarySlice from "../feature/LibrarySlice";
import BoardExam from "Redux/feature/BoardExam";
import ChapterExamSlice from "Redux/feature/ChapterExamSlice";
import AttendanceSlice from "Redux/feature/AttendanceSlice";
import StudentManagementExamResultSlice from "Redux/feature/StudentmanagementExamResultSlice";
import TeacherSlice from "Redux/feature/TeacherSlice";
import BoardingSlice from "Redux/feature/BordingSlice";
import ClassManagementSlice from "Redux/feature/classmanagementSlice";
import OnlinePracticeExamSlice from "Redux/feature/onlinePracticeExamSlice";
import EssayModuleSlice from "Redux/feature/EssayModuleSlice";
import StudentDiarySlice from "Redux/feature/StudentDiarySlice";
import StudentWorkgroupSlice from "Redux/feature/StudentWorkgroupSlice";

export const store = configureStore({
  reducer: {
    UserAuth: UserAuth,
    Questions: Questions,
    UserNotification: UserNotification,
    MeetingsSubjects: MeetingsSubjects,
    LessonsBook: LessonsBook,
    StudentsHomeData: StudentsHomeData,
    LibrarySlice: LibrarySlice,
    BoardExam: BoardExam,
    ChapterExamSlice: ChapterExamSlice,
    AttendanceSlice: AttendanceSlice,
    StudentManagementExamResultSlice: StudentManagementExamResultSlice,
    ClassManagementSlice: ClassManagementSlice,
    TeacherSlice: TeacherSlice,
    BoardingSlice: BoardingSlice,
    OnlinePracticeExamSlice: OnlinePracticeExamSlice,
    EssayModuleSlice: EssayModuleSlice,
    StudentDiarySlice: StudentDiarySlice,
    StudentWorkgroupSlice:StudentWorkgroupSlice

  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
