export const getImageUrl = (
  icon: string | undefined,
  defaultIcon: string
): string => {
  const imagePath = process.env.REACT_APP_IMAGE_PATH || "";

  if (!icon) {
    return defaultIcon; // Return the default icon if icon is empty or undefined
  }

  return `${imagePath}${icon}`;
};
