import { Box } from "@mui/material";
import { useAppDispatch } from "Redux/App/hooks";
import { setMathFlag } from "Redux/feature/Questions";
import { getImageUrl } from "hooks/imageUrl";
import { useWirisParser } from "hooks/useWiris";
import React, { useEffect, useRef } from "react";

type Props = {
  description: string;
  file_path?: string;
  view_column?: boolean;
};

const DescriptionBox = ({ description, file_path, view_column }: Props) => {
  const dispatch = useAppDispatch();
  const questionRef = useRef(null);
  const checkForMathTag = (text: string | string[]) => {
    return text.includes("<math");
  };
  useEffect(() => {
    if (description && checkForMathTag(description)) {
      dispatch(setMathFlag(true));
    }
  }, [description]);

  useWirisParser(questionRef);

  return (
    <>
      <div
        ref={questionRef}
        style={{
          width: "auto",
          backgroundColor: "#E8EBED",
          borderRadius: "10px",
          padding: "10px",
          position: "relative",
          left: 0,
        }}
      >
        <div
          style={{
            overflowX: "auto",
          }}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></div>
        <Box marginTop={2}>
          {file_path && (
            <img
              style={{
                maxWidth: "237px",
                minWidth: "141px",
                objectFit: "contain",
              }}
              src={getImageUrl(file_path, "")}
              alt="explanation"
              loading="lazy"
            />
          )}
        </Box>
      </div>
    </>
  );
};

export default DescriptionBox;
