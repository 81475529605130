import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axiosClient from "api/api";
import { createAction } from "@reduxjs/toolkit";

interface fetchQuestions {
  user_id:string | null;
  container_id:string | null;
  assignment_id?:string | null;
}
interface clearExam {
  user_id:string | null;
  container_id:string | null;
  assignment_id:string | null;
}
interface getOnlineExamChapter {
  student_id:string | null;
  chapter_id:string;
  assignment_id:string | null;
  is_next_chapter:number;
  main_container_id:string | null;
}
interface fetchQuestionsOnlineExam {
  student_id:string | null;
  practice_id:number | undefined;
  onlineExamType:string;
  course_id:string;
  level_status?:string;
  level?:number;
  question_index?:number;
  min_level?:number;
  max_level?:number;
}
interface saveQuestionNotes {
  user_id:string | null;
  question_id:string;
  course_id:string;
  container_id:string;
  notes:string;
  question_number:number;
  id?:string;
  activeQuestion:number;
  assignment_id:string;
}
interface getPracticeSummary {
  user_id:string | null;
  container_id:string | null;
  course_id:string;
}
interface pin_unpin_questionAnswer {
  student_id:string;
  notes_id:string;
  course_id:string;
}
interface getAllSubmittedQuestion {
  user_id?:string | null;
  assignment_id?:string | null;
  container_id?:string | number | null;
  type?:string;
}

export const FetchQuestions = createAsyncThunk(
  "Questions/fetchQuestion",
  async (data: fetchQuestions) => {
    let url = `get-container-questions?user_id=${data.user_id}&container_id=${data.container_id}`;
    if (data.assignment_id) {
      url += `&assignment_id=${data.assignment_id}`;
    }
    const res = await axiosClient.get(url);
    return res.data;
  }
);
export const ClearQuestions = createAsyncThunk(
  "Questions/clearQuestions",
  async (data: {user_id:string | null, container_id:string | null}) => {
    const res = await axiosClient.get(
      `clear-user-answer?user_id=${data.user_id}&container_id=${data.container_id}`
    );
    return res.data;
  }
);
export const ClearExam = createAsyncThunk(
  "Questions/clearExam",
  async (data: clearExam) => {
    const res = await axiosClient.get(
      `clear-user-answer?user_id=${data.user_id}&container_id=${data.container_id}&assignment_id=${data.assignment_id}`
    );
    return res.data;
  }
);

export const FetchQuestionsOnlineExam = createAsyncThunk(
  "Questions/fetchQuestionsOnlineExam",
  async (data: fetchQuestionsOnlineExam) => {
    try {
      if (
        data.level_status !== undefined &&
        data.level !== undefined &&
        data.question_index !== undefined
      ) {
        const res = await axiosClient.get(
          `get-online-practice-questions?student_id=${data.student_id}&practice_id=${data.practice_id}
          &level_status=${data.level_status}&level=${data.level}&question_index=${data.question_index}
          &onlineExamType=${data.onlineExamType}&course_id=${data.course_id}&min_level=${data.min_level}&max_level=${data.max_level}`
        );
        return res.data;
      } else {
        const res = await axiosClient.get(
          `get-online-practice-questions?student_id=${data.student_id}&practice_id=${data.practice_id}&onlineExamType=${data.onlineExamType}&course_id=${data.course_id}`
        );
        return res.data;
      }
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchOneQuestion = createAsyncThunk(
  "Questions/fetchOneQuestion",
  async (data: {question_id:string | null, collection_id:string | null, container_id:string | null}) => {
    const res = await axiosClient.get(
      `get-question-preview?question_id=${data.question_id}&collection_id=${data.collection_id}&container_id=${data.container_id}`
    );
    return res.data;
  }
);
export const GetOnlineExamChapter = createAsyncThunk(
  "Questions/getOnlineExamChapter",
  async (data: getOnlineExamChapter) => {
    const res = await axiosClient.post("get-online-exam-chapter", data);
    return res.data;
  }
);

export const SubmitQuestionsAns = createAsyncThunk(
  "Questions/submitAnswer",
  async (data: any) => {
    const res = await axiosClient.post("submit-question-answer", data);
    return res.data;
  }
);

export const SubmitQuestionsAnsForOnlineExam = createAsyncThunk(
  "Questions/submitQuestionsAnsForOnlineExam",
  async (data: any) => {
    const res = await axiosClient.post(
      "submit-online-practice-question-answer",
      data
    );
    return res.data;
  }
);
export const SaveQuestionNotes = createAsyncThunk(
  "Questions/questionsNotes",
  async (data: saveQuestionNotes) => {
    const res = await axiosClient.post("save-question-notes", data);
    return res.data;
  }
);
export const GetPracticeSummary = createAsyncThunk(
  "Questions/getPracticeSummary",
  async (data: getPracticeSummary) => {
    const res = await axiosClient.post("get-practice-summary", data);
    return res.data;
  }
);
export const PinQuestionAnswer = createAsyncThunk(
  "Questions/pinQuestionAnswer",
  async (data: pin_unpin_questionAnswer) => {
    const res = await axiosClient.post("pin-question-answer", data);
    return res.data;
  }
);
export const UnPinQuestionAnswer = createAsyncThunk(
  "Questions/unPinQuestionAnswer",
  async (data: pin_unpin_questionAnswer) => {
    const res = await axiosClient.post("unpin-question-answer", data);
    return res.data;
  }
);
export const GetAllSubmittedQuestion = createAsyncThunk(
  "Questions/getAllSubmittedQuestion",
  async (data: getAllSubmittedQuestion) => {
    try {
      const res = await axiosClient.post("get-all-question-answer", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const GetAllSubmittedQuestionForOnlineExam = createAsyncThunk(
  "Questions/getAllSubmittedQuestionForOnlineExam",
  async (data: any) => {
    const res = await axiosClient.post(
      "get-online-practice-question-answer",
      data
    );
    return res.data;
  }
);
export const GetQuestionNotes = createAsyncThunk(
  "Questions/GetAllSubmittedQuestion",
  async (data: any) => {
    const res = await axiosClient.get(
      `get-question-notes?container_id=${data.container_id}&user_id=${data.user_id}&question_id=${data.question_id}&question_number=${data.question_number}`
    );
    return res.data;
  }
);
export const ResetQuestionsState = createAction("Questions/resetState");

interface initialStage {
  submitQuestionsAnswer: any[];
  chapter_data: any[];
  mathFlag: boolean;
  isLoading: boolean;
  questions: any[];
  error: any;
  questionNotes: any[];
  answer_notes_list: any[];
  questionSummery: any[];
  pinQuestionsAnswer: any[];
  unpinQuestionsAnswer: any[];
  container_data: any[];
  isRandomContainerData: number;
  question_data: any[];
  activeStep: number;
  questionTime: string;
  activeQuestion: number;
  quizResultShow: boolean;
  isAllQuestionAttempt: boolean;
  setFlagProgressBarNotes: boolean;
  fetchAllSubmittedQuestions: [];
  totalCorrectAnswers: number;
  colorChange: boolean;
  progressBarColor: boolean;
  singleQuestionAttempt: boolean;
  numericBar: boolean;
  correctAnswerDetails: any[];
  chapters: any[];
  timerFlag: boolean;
  progressBarNavigationFlag: boolean;
  renderPreview: boolean;
  totalExamTime?: string;
  containerDetails: any[];
  sidebarData: any[];
  questionBar: boolean;
  isLevelUpButton: boolean;
  startIndex: number;
  action: boolean;
  resetBook: boolean;
  resetAss: boolean;
  showResult: boolean;
  showResultMode: boolean;
  showResultQuiz: boolean;
  showModalQuiz: boolean;
  remainTime: string;
  remainTimeQuiz: string;
  remainTimeSeries: string;
  showResultModeQuiz: boolean;
  isTimer: boolean;
  previewName: string;
  submittedNotes: any[];
  previousElement: boolean;
  researchTab: boolean;
  min_level: number;
  max_level: number;
  student_level: number;
  total_score: number;
  total_time: string;
  previousTime:string;
  up_btn_disable: number;
  down_btn_disable: number;
  show_result_for_practice:boolean
}
const initialState: initialStage = {
  submitQuestionsAnswer: [],
  isLoading: true,
  mathFlag: false,
  isTimer: false,
  questions: [],
  chapter_data: [],
  error: null,
  questionNotes: [],
  answer_notes_list: [],
  questionSummery: [],
  pinQuestionsAnswer: [],
  unpinQuestionsAnswer: [],
  container_data: [],
  isRandomContainerData: 0,
  question_data: [],
  activeStep: 0,
  activeQuestion: 0,
  questionTime: "",
  quizResultShow: false,
  isAllQuestionAttempt: false,
  setFlagProgressBarNotes: false,
  fetchAllSubmittedQuestions: [],
  totalCorrectAnswers: 0,
  colorChange: false,
  singleQuestionAttempt: false,
  progressBarColor: false,
  numericBar: false,
  correctAnswerDetails: [],
  chapters: [],
  timerFlag: false,
  progressBarNavigationFlag: false,
  researchTab: false,
  renderPreview: false,
  totalExamTime: "",
  containerDetails: [],
  sidebarData: [],
  questionBar: false,
  isLevelUpButton: false,
  startIndex: 0,
  action: false,
  resetBook: false,
  resetAss: false,
  showResult: false,
  showResultMode: false,
  showResultModeQuiz: false,
  remainTime: "",
  remainTimeQuiz:"",
  remainTimeSeries:"",
  showResultQuiz: false,
  showModalQuiz: false,
  previewName: "",
  submittedNotes: [],
  previousElement: false,
  min_level: 4,
  max_level: 6,
  student_level: 0,
  total_score: 0,
  total_time: "",
  previousTime:"10:00",
  up_btn_disable: 0,
  down_btn_disable: 0,
  show_result_for_practice:false
};

const Questions = createSlice({
  name: "allSubmitQuestions",
  initialState: initialState,
  reducers: {
    is_skipped_question: (state, action) => {
      const updateSkipped_question = state.questions.map((question) => {
        if (question.id === action.payload) {
          return { ...question, is_skipped_question: 1 };
        }
        return question;
      });

      return {
        ...state,
        questions: updateSkipped_question,
      };
    },
    updateActiveStep: (state, action) => {
      return { ...state, activeStep: action.payload };
    },
    setPreviousElement: (state, action) => {
      return { ...state, previousElement: action.payload };
    },
    setMathFlag: (state, action) => {
      return { ...state, mathFlag: action.payload };
    },
    setIsTimer: (state, action) => {
      return { ...state, isTimer: action.payload };
    },
    setPreviewName: (state, action) => {
      return { ...state, previewName: action.payload };
    },
    getQuestionTime: (state, action) => {
      return { ...state, questionTime: action.payload };
    },
    updateActiveQuestion: (state, action) => {
      return { ...state, activeQuestion: action.payload };
    },
    getQuizResultShow: (state, action) => {
      return { ...state, quizResultShow: action.payload };
    },
    setStartIndex: (state, action) => {
      return { ...state, startIndex: action.payload };
    },
    setAction: (state, action) => {
      return { ...state, action: action.payload };
    },
    setShowResult: (state, action) => {
      return { ...state, showResult: action.payload };
    },
    setShowResultForPractice: (state, action) => {
      return { ...state, show_result_for_practice: action.payload };
    },
    setShowResultMode: (state, action) => {
      return { ...state, showResultMode: action.payload };
    },
    setRemainTime: (state, action) => {
      return { ...state, remainTime: action.payload };
    },
    setRemainTimeQuiz: (state, action) => {
      return { ...state, remainTimeQuiz: action.payload };
    },
    setRemainTimeSeries: (state, action) => {
      return { ...state, remainTimeSeries: action.payload };
    },
    setShowResultModeQuiz: (state, action) => {
      return { ...state, showResultModeQuiz: action.payload };
    },
    setShowResultQuiz: (state, action) => {
      return { ...state, showResultQuiz: action.payload };
    },
    setShowModalQuiz: (state, action) => {
      return { ...state, showModalQuiz: action.payload };
    },
    setQuizQuestionComplete: (state, action) => {
      return { ...state, isAllQuestionAttempt: action.payload };
    },
    setProgressBarFlag: (state, action) => {
      return { ...state, setFlagProgressBarNotes: action.payload };
    },
    setFetchAllSubmittedQuestions: (state, action) => {
      return { ...state, fetchAllSubmittedQuestions: action.payload };
    },
    setQuizColorChange: (state, action) => {
      return { ...state, colorChange: action.payload };
    },
    setProgressBarColor: (state, action) => {
      return { ...state, progressBarColor: action.payload };
    },
    setNumericBar: (state, action) => {
      return { ...state, numericBar: action.payload };
    },
    setQuestionBar: (state, action) => {
      return { ...state, questionBar: action.payload };
    },
    setSingleQuestionAttempt: (state, action) => {
      return { ...state, singleQuestionAttempt: action.payload };
    },
    setTimerFlag: (state, action) => {
      return { ...state, timerFlag: action.payload };
    },
    setProgressBarNavigation: (state, action) => {
      return { ...state, progressBarNavigationFlag: action.payload };
    },
    setRenderPreview: (state, action) => {
      return { ...state, renderPreview: action.payload };
    },
    setTotalExamTime: (state, action) => {
      return { ...state, totalExamTime: action.payload };
    },
    setPreviousTime: (state, action) => {
      return { ...state, previousTime: action.payload };
    },
    setResetBook: (state, action) => {
      return { ...state, resetBook: action.payload };
    },
    setResetAss: (state, action) => {
      return { ...state, resetAss: action.payload };
    },
    setContainerDetails: (state, action) => {
      return { ...state, containerDetails: action.payload };
    },
    setSideBarData: (state, action) => {
      return { ...state, sidebarData: action.payload };
    },
    setStudentLevel: (state, action) => {
      return { ...state, student_level: action.payload };
    },
    updateNumericBarFlag: (state, action) => {
      const { id, isSkip, isCompleted } = action.payload;
      const questionIndex = state.questions.findIndex(
        (question) => question.id === id
      );
      if (questionIndex !== -1) {
        state.questions[questionIndex] = {
          ...state.questions[questionIndex],
          is_skipped_question: isSkip,
          is_completed: isCompleted,
        };
      }
    },
    replacePendingQuestions: (state, action) => {
      const { newQuestions, activeQuestion, oldQuestions, isLoading } =
        action.payload;
      const startIndex = state.activeQuestion;
      const currentQuestions = [...oldQuestions];
      currentQuestions.splice(startIndex, newQuestions.length, ...newQuestions);
      if (!isLoading || currentQuestions) {
        return {
          ...state,
          questions: currentQuestions,
          activeQuestion: activeQuestion,
        };
      } else {
        return { ...state };
      }
    },
    setResearchTab: (state, action) => {
      return { ...state, researchTab: action.payload };
    },
  },

  extraReducers(builder) {
    builder
      .addCase(FetchQuestions.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
          questions: [],
        };
      })
      .addCase(FetchQuestions.fulfilled, (state, action) => {
        const modifiedData = filterAndAddKeys(
          action.payload.data,
          action.payload.question_data
        );

        return {
          ...state,
          isLoading: false,
          questions: modifiedData,
          container_data: action.payload.container_data,
          question_data: action.payload.question_data,
          isRandomContainerData: action.payload.container_data?.is_random,
        };
      })
      .addCase(FetchQuestions.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(ClearQuestions.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(ClearQuestions.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(ClearQuestions.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(ClearExam.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(ClearExam.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(ClearExam.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })

      .addCase(FetchQuestionsOnlineExam.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
          error: action.payload,
          questions: [],
        };
      })
      .addCase(FetchQuestionsOnlineExam.fulfilled, (state, action) => {
        const modifiedData = filterAndAddKeys(
          action.payload.data,
          action.payload.question_data
        );
        return {
          ...state,
          isLoading: false,
          questions: modifiedData,
          container_data: action.payload.container_data,
          isRandomContainerData: action.payload.container_data?.is_random,
          question_data: action.payload.question_data,
          max_level: action.payload.max_level,
          min_level: action.payload.min_level,
          up_btn_disable: action.payload.disable_up_btn,
          down_btn_disable: action.payload.disable_down_btn,
          error: action.payload.message,
        };
      })
      .addCase(FetchQuestionsOnlineExam.rejected, (state, action) => {
        return {
          ...state,
          error: action.error.message,
          isLoading: false,
        };
      })

      .addCase(FetchOneQuestion.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
          renderPreview: true,
          questions: [],
        };
      })
      .addCase(FetchOneQuestion.fulfilled, (state, action) => {
        const modifiedData = filterAndAddKeys(
          action.payload.data,
          action.payload.question_data
        );

        return {
          ...state,
          isLoading: false,
          questions: modifiedData,
          container_data: action.payload.container_data,
          question_data: action.payload.question_data,
        };
      })
      .addCase(FetchOneQuestion.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          renderPreview: true,
          error: action.payload,
        };
      })
      .addCase(GetOnlineExamChapter.pending, (state, action) => {
        return {
          ...state,
          submitQuestionsAnswer: [],
        };
      })
      .addCase(GetOnlineExamChapter.fulfilled, (state, action) => {
        return {
          ...state,
          chapter_data: action.payload.data,
        };
      })
      .addCase(GetOnlineExamChapter.rejected, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(SubmitQuestionsAns.pending, (state, action) => {
        return {
          ...state,
          submitQuestionsAnswer: [],
        };
      })
      .addCase(SubmitQuestionsAns.fulfilled, (state, action) => {
        return {
          ...state,
          submitQuestionsAnswer: action.payload.data,
        };
      })
      .addCase(SubmitQuestionsAns.rejected, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(SubmitQuestionsAnsForOnlineExam.pending, (state, action) => {
        return {
          ...state,
          submitQuestionsAnswer: [],
        };
      })
      .addCase(SubmitQuestionsAnsForOnlineExam.fulfilled, (state, action) => {
        return {
          ...state,
          submitQuestionsAnswer: action.payload.data,
        };
      })
      .addCase(SubmitQuestionsAnsForOnlineExam.rejected, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(SaveQuestionNotes.pending, (state, action) => {
        return {
          ...state,
          questionNotes: [],
        };
      })
      .addCase(SaveQuestionNotes.fulfilled, (state, action) => {
        return {
          ...state,
          questionNotes: action.payload.data,
        };
      })
      .addCase(SaveQuestionNotes.rejected, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(GetPracticeSummary.pending, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(GetPracticeSummary.fulfilled, (state, { payload }) => {
        return {
          ...state,
          questionSummery: payload.data,
          answer_notes_list: payload.data.answer_notes_list,
        };
      })
      .addCase(GetPracticeSummary.rejected, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(PinQuestionAnswer.pending, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(PinQuestionAnswer.fulfilled, (state, { payload }) => {
        const pinArray = [...current(state.answer_notes_list)];
        let findindex = pinArray.findIndex(
          (item) => item.id === payload.data.id
        );
        if (findindex > -1) {
          pinArray[findindex] = {
            ...pinArray[findindex],
            is_pinned: payload.data.is_pinned,
          };
        }
        state.answer_notes_list = pinArray;
      })

      .addCase(PinQuestionAnswer.rejected, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(UnPinQuestionAnswer.pending, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(UnPinQuestionAnswer.fulfilled, (state, { payload }) => {
        const unPinArray = [...current(state.answer_notes_list)];
        let findindex = unPinArray.findIndex(
          (item) => item.id === payload.data.id
        );
        if (findindex > -1) {
          unPinArray[findindex] = {
            ...unPinArray[findindex],
            is_pinned: payload.data.is_pinned,
          };
        }
        state.answer_notes_list = unPinArray;
      })
      .addCase(UnPinQuestionAnswer.rejected, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(GetAllSubmittedQuestion.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetAllSubmittedQuestion.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          fetchAllSubmittedQuestions: action.payload.data.user_answer_detail,
          totalCorrectAnswers: action.payload.data.total_correct_ans,
          correctAnswerDetails: action.payload.data.correct_ans_data,
          total_score: action.payload.data.total_score,
          submittedNotes: action.payload.data.notes,
          chapters: action.payload.chapter,
          subject: action?.payload?.data?.subject,
          total_time: action?.payload?.data?.time_taken_for_answer,
        };
      })
      .addCase(GetAllSubmittedQuestion.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })

      .addCase(
        GetAllSubmittedQuestionForOnlineExam.pending,
        (state, action) => {
          return {
            ...state,
            isLoading: true,
          };
        }
      )
      .addCase(
        GetAllSubmittedQuestionForOnlineExam.fulfilled,
        (state, action) => {
          return {
            ...state,
            isLoading: false,
            fetchAllSubmittedQuestions: action.payload.data.user_answer_detail,
            totalCorrectAnswers: action.payload.data.total_correct_ans,
            correctAnswerDetails: action.payload.data.correct_ans_data,
            chapters: action.payload.chapter,
            submittedNotes: action.payload.data.notes,
          };
        }
      )
      .addCase(
        GetAllSubmittedQuestionForOnlineExam.rejected,
        (state, action) => {
          return {
            ...state,
            isLoading: false,
            error: action.payload,
          };
        }
      )
      .addCase(ResetQuestionsState, (state) => {
        return { ...initialState };
      });
  },
});

function filterAndAddKeys(data: any, questionData: any) {
  data.forEach((question: any) => {
    const matchingQuestion = questionData.find(
      (q: any) => q.question_id === question.id
    );

    if (matchingQuestion) {
      question.is_correct_answer = matchingQuestion.is_correct_answer;
      question.is_skipped_question = matchingQuestion.is_skipped_question;
      question.time_taken_for_answer = matchingQuestion.time_taken_for_answer;
    }
  });

  return data;
}
export const {
  is_skipped_question,
  updateActiveStep,
  getQuestionTime,
  updateActiveQuestion,
  getQuizResultShow,
  setQuizQuestionComplete,
  setProgressBarFlag,
  setFetchAllSubmittedQuestions,
  setQuizColorChange,
  setProgressBarColor,
  setNumericBar,
  updateNumericBarFlag,
  setSingleQuestionAttempt,
  setTimerFlag,
  setProgressBarNavigation,
  setRenderPreview,
  setTotalExamTime,
  setContainerDetails,
  setQuestionBar,
  setAction,
  replacePendingQuestions,
  setStartIndex,
  setResetBook,
  setResetAss,
  setShowResult,
  setShowResultMode,
  setPreviewName,
  setMathFlag,
  setShowResultModeQuiz,
  setShowResultQuiz,
  setShowModalQuiz,
  setPreviousElement,
  setStudentLevel,
  setRemainTime,
  setRemainTimeQuiz,
  setRemainTimeSeries,
  setResearchTab: setResearchTab,
  setIsTimer,
  setPreviousTime,
  setShowResultForPractice
} = Questions.actions;

export default Questions.reducer;
