import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";

interface teacher_id {
  teacher_id: string | null;
}
interface student_id {
  student_id: string | null;
}
interface course_id {
  course_id: string;
}
interface student_course_id {
  student_id: string | null;
  course_id: string | number | null;
}
interface saveQuestion {
  explanation: string;
  teacher_id: string | null;
  course_id: string;
  number_of_questions: number;
  questionData: any[];
}
interface saveQuestions {
  student_id: string | null;
  course_id: number;
  board_exam_id: number;
  questionData: any[];
}

export const FetchCourses = createAsyncThunk(
  "BoardExam/getCourses",
  async (data: teacher_id) => {
    try {
      const res = await axiosClient.get(
        `get-courses?teacher_id=${data.teacher_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchCoursesForStudent = createAsyncThunk(
  "BoardExam/fetchCoursesForStudent",
  async (data: student_id) => {
    try {
      const res = await axiosClient.get(
        `get-student-courses?student_id=${data.student_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const SaveBoardQuestions = createAsyncThunk(
  "BoardExam/saveBoardQuestions",
  async (data: saveQuestion) => {
    try {
      const res = await axiosClient.post("save-board-questions", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchBoardQuestions = createAsyncThunk(
  "BoardExam/getBoardQuestions",
  async (data: course_id) => {
    try {
      const res = await axiosClient.get(
        `get-board-questions?course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const SaveBoardQuestionsAns = createAsyncThunk(
  "BoardExam/saveBoardQuestions-ans",
  async (data: saveQuestions) => {
    try {
      const res = await axiosClient.post("save-board-question-ans", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchBoardExam = createAsyncThunk(
  "BoardExam/getBoardExam",
  async (data: student_course_id) => {
    try {
      const res = await axiosClient.get(
        `get-board-exam?student_id=${data.student_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchBoardExamResult = createAsyncThunk(
  "BoardExam/getBoardExamResult",
  async (data: teacher_id) => {
    try {
      const res = await axiosClient.get(
        `get-board-exam-results?teacher_id=${data.teacher_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchSubmittedStudentCount = createAsyncThunk(
  "BoardExam/getSubmittedStudentCount",
  async (data: teacher_id) => {
    try {
      const res = await axiosClient.get(
        `get-student-submitted-count?teacher_id=${data.teacher_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
interface initialStage {
  course: any[];
  isLoading: boolean;
  error: any;
  submitBoardExam: any[];
  boardQuestions: any[];
  saveBoardQuesAns: any[];
  boardExamStudent: any[];
  boardExamResult: any[];
  submittedStudentCount: any[];
  selectedCourse: number;
  courseLabel: string;
  student_flag: any;
}

const initialState: initialStage = {
  course: [],
  isLoading: false,
  error: null,
  submitBoardExam: [],
  boardQuestions: [],
  saveBoardQuesAns: [],
  boardExamStudent: [],
  boardExamResult: [],
  submittedStudentCount: [],
  selectedCourse: 0,
  courseLabel: "",
  student_flag: {},
};

const BoardExam = createSlice({
  name: "BoardExam",
  initialState,
  reducers: {
    updateSelectedCourseId: (state, action) => {
      return { ...state, selectedCourse: action.payload };
    },
    updateCourseLabel: (state, action) => {
      return { ...state, courseLabel: action.payload };
    },
  },

  extraReducers(builder) {
    builder
      .addCase(FetchCourses.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchCourses.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          course: action.payload.data,
        };
      })
      .addCase(FetchCourses.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchCoursesForStudent.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchCoursesForStudent.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          course: action.payload.data,
        };
      })
      .addCase(FetchCoursesForStudent.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(SaveBoardQuestions.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(SaveBoardQuestions.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          submitBoardExam: action.payload,
        };
      })
      .addCase(SaveBoardQuestions.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchBoardQuestions.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchBoardQuestions.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          boardQuestions: action.payload.data,
        };
      })
      .addCase(FetchBoardQuestions.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(SaveBoardQuestionsAns.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(SaveBoardQuestionsAns.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          saveBoardQuesAns: action.payload.data,
        };
      })
      .addCase(SaveBoardQuestionsAns.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchBoardExam.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchBoardExam.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          boardExamStudent: action.payload.data,
          student_flag: action.payload.flag_data,
        };
      })
      .addCase(FetchBoardExam.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchBoardExamResult.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchBoardExamResult.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          boardExamResult: action.payload.data,
        };
      })
      .addCase(FetchBoardExamResult.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchSubmittedStudentCount.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchSubmittedStudentCount.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          submittedStudentCount: action.payload.data,
        };
      })
      .addCase(FetchSubmittedStudentCount.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      });
  },
});

export const { updateSelectedCourseId, updateCourseLabel } = BoardExam.actions;

export default BoardExam.reducer;
