import { useAppDispatch } from "Redux/App/hooks";
import { setMathFlag } from "Redux/feature/Questions";
import React, { useEffect, useState } from "react";

const Loader: React.FC = () => {
  const loaderContainerStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const loaderStyle: React.CSSProperties = {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    border: "5px solid #ddd",
    borderTopColor: "#6A3FA1",
    animation: "loading 1s linear infinite",
  };
  const textStyle: React.CSSProperties = {
    fontSize: "18px",
    fontWeight: "400",
    color: "#000000",
  };
  //   const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setMathFlag(true));
    // Set a timeout to stop loading after 2 seconds
    const timer: any = setTimeout(() => dispatch(setMathFlag(false)), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={loaderContainerStyle}>
      <div style={loaderStyle}></div>
      <span style={textStyle}>...Loading</span>
    </div>
  );
};

export default Loader;
