import { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  FormHelperText,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { Error, CheckCircleOutlined } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import { FetchClientSetPassword } from "Redux/feature/UserAuth";

const CreatePassword = () => {
  const workerId = useAppSelector((state) => state.UserAuth.workerId);
  const oTPVerifiedToken = useAppSelector(
    (state) => state.UserAuth.oTPVerifiedToken
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);

  const [values, setValues] = useState<{
    password: string;
    verifyPassword: string;
    showPassword: boolean;
    showVerifyPassword: boolean;
  }>({
    password: "",
    verifyPassword: "",
    showPassword: false,
    showVerifyPassword: false,
  });

  const errorMessage = {
    required: <FormattedMessage id="required-field-message" />,
    digit: <FormattedMessage id="digits-field-message" />,
    capitalLetter: <FormattedMessage id="capital-letter-field-message" />,
    length: <FormattedMessage id="length8-field-message" />,
    verify: <FormattedMessage id="verification-field-message" />,
  };

  const handleChange = (type: string, value: string) => {
    if (type === "password") setValues({ ...values, password: value });
    if (type === "verifyPassword")
      setValues({ ...values, verifyPassword: value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setSubmitted(true);
    const data = new FormData(event.currentTarget);
    if (isError(values.password) || isError(values.verifyPassword, true)) {
      return;
    }
    dispatch(
      FetchClientSetPassword({
        otp_verified_token: oTPVerifiedToken,
        username: workerId,
        password: data.get("verify_password"),
      })
    ).then((res) => {
      res.payload.status ? navigate("/") : alert(res.payload.message);
    });
  };

  useEffect(() => {
    if (values.password === "" && values.verifyPassword === "") {
      setSubmitted(false);
    }
  }, [values]);

  useEffect(() => {
    if (workerId === "") {
      navigate("/");
    }
  }, [workerId]);

  const isError = (value: string, verifyCheck?: boolean) => {
    return (
      value === "" ||
      value.length < 8 ||
      !/[A-Z]/.test(value) ||
      !/[0-9]/.test(value) ||
      (verifyCheck && value !== values.password)
    );
  };

  const getErrorMessage = (value: string, verifyCheck?: boolean) => {
    if (value === "") return errorMessage.required;
    if (!/[A-Z]/.test(value)) return errorMessage.capitalLetter;
    if (!/[0-9]/.test(value)) return errorMessage.digit;
    if (value.length < 8) return errorMessage.length;
    if (verifyCheck && value !== values.password) return errorMessage.verify;
    return "";
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        width={{ xs: "100%", sm: "394px" }}
        height={{ xs: "100%", md: "calc(100vh - 215px)" }}
        sx={{
          mx: 4,
          marginTop: "85px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: "15px",
          pb: "35px",
        }}
      >
        <Box width="100%">
          <Typography sx={{ fontSize: "32px", fontWeight: 700 }}>
            <FormattedMessage id="setting-password" />
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, width: "100%" }}
        >
          <Typography variant="subtitle1">
            <FormattedMessage id="password-rules-info" />
            <ul>
              {submitted === false ? (
                <li style={{ color: "#4A5056" }}>
                  <FormattedMessage id="length8-rule" />
                </li>
              ) : values.password.length < 8 ? (
                <li style={{ color: "#E74C3C" }}>
                  <FormattedMessage id="length8-rule" />
                </li>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleOutlined
                    sx={{ ml: "3px", marginRight: "-30px", color: "#33CD75" }}
                  />
                  <label>
                    <FormattedMessage id="length8-rule" />
                  </label>
                </Box>
              )}
              {submitted === false ? (
                <li style={{ color: "#4A5056" }}>
                  <FormattedMessage id="digits-rule" />
                </li>
              ) : !/[0-9]/.test(values.password) ? (
                <li style={{ color: red[700] }}>
                  <FormattedMessage id="digits-rule" />
                </li>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleOutlined
                    sx={{ ml: "3px", marginRight: "-30px", color: "#33CD75" }}
                  />
                  <label>
                    <FormattedMessage id="digits-rule" />
                  </label>
                </Box>
              )}
              {submitted === false ? (
                <li style={{ color: "#4A5056" }}>
                  <FormattedMessage id="capital-letter-rule" />
                </li>
              ) : !/[A-Z]/.test(values.password) ? (
                <li style={{ color: red[700] }}>
                  <FormattedMessage id="capital-letter-rule" />
                </li>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleOutlined
                    sx={{ ml: "3px", marginRight: "-30px", color: "#33CD75" }}
                  />
                  <label>
                    <FormattedMessage id="capital-letter-rule" />
                  </label>
                </Box>
              )}
            </ul>
          </Typography>
          <InputLabel sx={{ marginTop: "25px" }} htmlFor="password">
            <FormattedMessage id="password" />
          </InputLabel>
          <FormControl
            error={submitted && isError(values.password)}
            sx={{ m: 1, mr: 0, width: "100%" }}
            variant="outlined"
          >
            <OutlinedInput
              value={values.password}
              onChange={(e) => handleChange("password", e.target.value)}
              type={values.showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setValues({
                        ...values,
                        showPassword: !values.showPassword,
                      })
                    }
                  >
                    {values.showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_17054_826)">
                          <path
                            d="M14.12 14.12C13.8454 14.4147 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.481 9.80385 14.1962C9.51897 13.9113 9.29439 13.5719 9.14351 13.1984C8.99262 12.8248 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2218 9.18488 10.8538C9.34884 10.4859 9.58525 10.1547 9.88 9.88M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.6819 3.96914 7.65661 6.06 6.06L17.94 17.94ZM9.9 4.24C10.5883 4.07888 11.2931 3.99834 12 4C19 4 23 12 23 12C22.393 13.1356 21.6691 14.2047 20.84 15.19L9.9 4.24Z"
                            stroke="#AEB5BC"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 1L23 23"
                            stroke="#AEB5BC"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_17054_826">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                          stroke="#AEB5BC"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                          stroke="#AEB5BC"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                  </IconButton>
                  {submitted && isError(values.password) && (
                    <Error
                      sx={{
                        position: "absolute",
                        left: "-30px",
                        color: "#E74C3C",
                      }}
                    />
                  )}
                </InputAdornment>
              }
              margin="none"
              fullWidth
              id="password"
              name="password"
              size="small"
              placeholder="הגדרת סיסמה"
              sx={{ fontSize: "16px" }}
              aria-describedby="component-error-text"
            />
            <FormHelperText
              style={{ textAlign: "right", color: "#E74C3C" }}
              id="component-error-text"
            >
              {submitted && getErrorMessage(values.password)}
            </FormHelperText>
          </FormControl>

          <InputLabel sx={{ marginTop: "20px" }} htmlFor="verify_password">
            <FormattedMessage id="password-verification" />
          </InputLabel>
          <FormControl
            error={submitted && isError(values.verifyPassword, true)}
            sx={{ m: 1, mr: 0, width: "100%" }}
            variant="outlined"
          >
            <OutlinedInput
              value={values.verifyPassword}
              onChange={(e) => handleChange("verifyPassword", e.target.value)}
              type={values.showVerifyPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setValues({
                        ...values,
                        showVerifyPassword: !values.showVerifyPassword,
                      })
                    }
                  >
                    {values.showVerifyPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_17054_826)">
                          <path
                            d="M14.12 14.12C13.8454 14.4147 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.481 9.80385 14.1962C9.51897 13.9113 9.29439 13.5719 9.14351 13.1984C8.99262 12.8248 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2218 9.18488 10.8538C9.34884 10.4859 9.58525 10.1547 9.88 9.88M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.6819 3.96914 7.65661 6.06 6.06L17.94 17.94ZM9.9 4.24C10.5883 4.07888 11.2931 3.99834 12 4C19 4 23 12 23 12C22.393 13.1356 21.6691 14.2047 20.84 15.19L9.9 4.24Z"
                            stroke="#AEB5BC"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 1L23 23"
                            stroke="#AEB5BC"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_17054_826">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                          stroke="#AEB5BC"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                          stroke="#AEB5BC"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                  </IconButton>
                  {submitted && isError(values.verifyPassword, true) && (
                    <Error
                      sx={{
                        position: "absolute",
                        left: "-30px",
                        color: "#E74C3C",
                      }}
                    />
                  )}
                </InputAdornment>
              }
              margin="none"
              // required
              fullWidth
              id="verify_password"
              name="verify_password"
              size="small"
              placeholder="הגדרת סיסמה"
              sx={{ fontSize: "16px" }}
            />
            <FormHelperText
              style={{ textAlign: "right", color: "#E74C3C" }}
              id="component-error-text"
            >
              {submitted && getErrorMessage(values.verifyPassword, true)}
            </FormHelperText>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: "25px", mb: 2, height: "40px", fontSize: "18px" }}
          >
            <FormattedMessage id="login-system" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreatePassword;
