import { useRoutes } from "react-router-dom";

import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import LibraryRoutes from "./LibraryRoutes";
import TeacherRoutes from "./TeacherRoutes";

export default function ThemeRoutes() {
  return useRoutes([LoginRoutes, MainRoutes, TeacherRoutes, LibraryRoutes]);
}
