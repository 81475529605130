import React, { useEffect, useState } from "react";
import { IconButton, Modal, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  FetchQuestions,
  GetAllSubmittedQuestion,
  setProgressBarFlag,
  setQuizQuestionComplete,
  SubmitQuestionsAns,
  updateActiveQuestion,
  setQuizColorChange,
  setTimerFlag,
  setTotalExamTime,
  setStartIndex,
  setProgressBarNavigation,
  setRemainTimeQuiz,
} from "Redux/feature/Questions";
import OpenTextBox from "../QuestionTypes/OpenTextBox";
import QuestionSideBar from "../Sidebar/QuestionSideBar";
import TextImageWithQuestion from "../QuestionTypes/TextImageWithQuestion";
import NumericBulletSingleChoice from "../QuestionTypes/NumericBulletSingleChoice";
import LongText from "../QuestionTypes/LongText";
import ImageQuestion from "../QuestionTypes/ImageQuestion";
import EnglishLayout from "../QuestionTypes/EnglishLayout";
import Quantitative from "../QuestionTypes/Quantative";
import VerbatimText from "../QuestionTypes/VerbatimText";
import { FormattedMessage } from "react-intl";
import { Typography, Button, Box, Stack } from "@mui/material";
import { useTimer } from "hooks/useTimer";
import { useSearchParams } from "react-router-dom";
import ModalComponent from "../ModalComponent/ModalComponent";
import { ReactComponent as EditIconCollaps } from "../../../assets/images/editIconCollaps.svg";
import { ReactComponent as EditIconCollapsModel } from "../../../assets/images/EditIconCollapsModel.svg";
import { ReactComponent as ArrowRight } from "../../../assets/images/ArrowRight.svg";
import AssignmentDialog from "views/common/AssignmentDialog";
import {
  CompleteContainerAtomic,
  FetchAtomicAndContainerData,
} from "Redux/feature/StudentHome";
import SessionDialog from "views/common/SessionDialog";
import ImageAndText from "../QuestionTypes/ImageandText";
import { setCurrentTab } from "Redux/feature/StudentmanagementExamResultSlice";
import { getCookie } from "views/common/cookieUtils";
import useTimerSecond from "hooks/useTimerSecond";
import { subtractTimes } from "utils/helperfunctions/getquestiontimevalue";

type Props = {};

const QuizType = (props: Props) => {
  const location = useLocation();
  const { questions, isLoading, container_data }: any = useAppSelector(
    (state) => state.Questions
  );
  const progressBarNavigationFlag: boolean = useAppSelector(
    (state) => state.Questions.progressBarNavigationFlag
  );
  let atomicUnitData: any = useAppSelector(
    (state) => state.StudentsHomeData.atomicUnitsData
  );
  const [showResult, setShowResult] = useState<any>(false);
  const [showResultMode, setShowResultMode] = useState(false);
  const user_id = getCookie("id");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCollapsed2, setIsCollapsed2] = useState(true);
  const [modalSubmitData, setModalSubmitData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const assignmentModal: boolean = useAppSelector(
    (state) => state.StudentsHomeData.openAssignmentSuccess
  );
  const sessionModal: boolean = useAppSelector(
    (state) => state.StudentsHomeData.openSessionSuccess
  );
  const [showModal, setShowModal] = useState(false);
  const selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  const remainTime = useAppSelector((state) => state.Questions.remainTimeQuiz);

  const dispatch = useAppDispatch();
  // const examTime = useTimer(location?.state?.examTime || "10:00");
  const { time, start, setTime, reset } = useTimerSecond(
    location?.state?.examTime || "10:00"
  );
  const examTime = time;

  const navigate = useNavigate();
  const containerID = searchParams.get("container_id");
  const assignment_id = searchParams.get("assignment_id");
  const isSmallScreen = useMediaQuery("(min-width: 900px)");
  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );
  const allQuestionAttempt: boolean = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );
  const totalQuestions = questions?.length;
  const defaultCourseId = getCookie("default_course_id");

  useEffect(() => {
    if (user_id && containerID) {
      dispatch(
        FetchQuestions({
          user_id: user_id,
          container_id: containerID,
        })
      ).then((resp: any) => {
        start();
        dispatch(
          setRemainTimeQuiz(resp?.payload?.container_data?.total_examtime)
        );
        dispatch(
          setTotalExamTime(resp?.payload?.container_data?.total_examtime)
        );
      });
    }
  }, [containerID, user_id]);

  useEffect(() => {
    if (allQuestionAttempt) {
      localStorage.removeItem("questionData");
    }
  }, [allQuestionAttempt]);
  useEffect(() => {
    if (!allQuestionAttempt) {
      dispatch(setProgressBarNavigation(false));
    }
  }, [allQuestionAttempt]);
  useEffect(() => {
    if (!allQuestionAttempt && questions) {
      const questionsIdArray = questions.map((question: { id: any }) => ({
        questionId: question.id,
      }));
      localStorage.setItem("questionData", JSON.stringify(questionsIdArray));
    }
  }, [!allQuestionAttempt, questions]);

  useEffect(() => {
    const pageSize = isSmallScreen ? 10 : 5;

    const calculateStartIndex = (question: any) =>
      Math.floor(question / pageSize) * pageSize;

    let nextStartIndex;

    if (!isSmallScreen) {
      nextStartIndex = calculateStartIndex(activeQuestion);
    } else {
      nextStartIndex = calculateStartIndex(activeQuestion);
    }

    dispatch(setStartIndex(nextStartIndex));
  }, [activeQuestion, isSmallScreen]);

  const getQuestionData = () => {
    const storedData = localStorage.getItem("questionData");
    return storedData ? JSON.parse(storedData) : [];
  };
  const addQuestionData = (questionId: any, optionId: any) => {
    const questionData = getQuestionData();

    const existingIndex = questionData.findIndex(
      (item: any) => item.questionId == questionId
    );

    if (existingIndex !== -1) {
      questionData[existingIndex].optionId = optionId;
    } else {
      questionData.push({ questionId, optionId });
    }

    localStorage.setItem("questionData", JSON.stringify(questionData));
  };

  const handleNextCheckAnswer = () => {
    dispatch(
      GetAllSubmittedQuestion({
        user_id: user_id,
        container_id: containerID,
      })
    );
    dispatch(setTimerFlag(true));
    if (showResultMode === false) {
      setShowResultMode(true);
      dispatch(updateActiveQuestion(0));
    }
    if (activeQuestion === questions.length - 1) {
      setShowResult(true);
      dispatch(setQuizQuestionComplete(true));
      dispatch(setQuizColorChange(true));
    } else {
      dispatch(updateActiveQuestion(activeQuestion + 1));
    }
    dispatch(setProgressBarFlag(true));
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const totalCorrectAnswer: any = useAppSelector(
    (state) => state.Questions.totalCorrectAnswers
  );
  const total_score = useAppSelector((state) => state.Questions.total_score);
  const handleSummaryPage = () => {
    if (location.state.isFromTable) {
      navigate("/home/learning-management");
      dispatch(setCurrentTab(1));
    } else {
      dispatch(
        GetAllSubmittedQuestion({
          user_id: user_id,
          container_id: containerID,
        })
      );
      dispatch(setQuizQuestionComplete(true));
      dispatch(setStartIndex(0));
      dispatch(updateActiveQuestion(0));
      navigate(
        `/practice-summary?container_type_id=${searchParams.get(
          "container_type_id"
        )}&container_id=${searchParams.get(
          "container_id"
        )}&assignment_id=${searchParams.get("assignment_id")}`
      );
    }
  };
  const handleAnswerSubmit = (data: any) => {
    addQuestionData(data.question_id, data.answer);
    const allQuestionData = getQuestionData();
    if (activeQuestion + 1 == questions.length) {
      const meetingIndex = atomicUnitData?.findIndex((item: any) => {
        return item.meeting.assignment.some(
          (assignmentData: any) => assignmentData.id == assignment_id
        );
      });
      if (user_id) {
        dispatch(
          CompleteContainerAtomic({
            student_id: user_id,
            assignment_id: searchParams.get("assignment_id"),
            meeting_id: localStorage.getItem("meetingId")
              ? localStorage.getItem("meetingId")
              : atomicUnitData[meetingIndex]?.meeting?.id,
            course_id: defaultCourseId ? defaultCourseId : selectedCourse,
            type: "container",
            id: searchParams.get("container_id"),
          })
        ).then((res: any) => {
          dispatch(
            FetchAtomicAndContainerData({
              id: user_id,
              course_id: defaultCourseId ? defaultCourseId : selectedCourse,
            })
          );
        });
      }
    }
    if (data.answer === undefined || data.answer === "") {
      setModalSubmitData(data);
      dispatch(
        SubmitQuestionsAns({
          user_id: data.user_id,
          container_id: data.container_id,
          question_id: data.question_id,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          collection_id: "",
          answer: "",
          is_skipped_question: 1,
          notes: "",
          time_taken_for_answer: data.time_taken_for_answer,
        })
      ).then((res: any) => {
        const remainingTime = subtractTimes(
          remainTime,
          data.time_taken_for_answer
        );
      setTime(remainingTime);
        dispatch(setRemainTimeQuiz(remainingTime));
        dispatch(updateActiveQuestion(activeQuestion + 1));

        if (activeQuestion == totalQuestions - 1) {
          dispatch(updateActiveQuestion(activeQuestion));
          const hasMissingOptionId = allQuestionData.some(
            (item: { hasOwnProperty: (arg0: string) => any }) =>
              !item.hasOwnProperty("optionId")
          );
          setShowModal(hasMissingOptionId);
        }
      });
    } else {
      dispatch(
        SubmitQuestionsAns({
          user_id: data.user_id,
          container_id: data.container_id,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          question_id: data.question_id,
          collection_id: "",
          answer: data.answer,
          is_skipped_question: 0,
          notes: "",
          time_taken_for_answer: data.time_taken_for_answer,
        })
      ).then((res: any) => {
        const remainingTime = subtractTimes(
          remainTime,
          data.time_taken_for_answer
        );
      setTime(remainingTime);
        dispatch(setRemainTimeQuiz(remainingTime));
        if (activeQuestion + 1 === questions.length) {
          const hasMissingOptionId = allQuestionData.some(
            (item: { hasOwnProperty: (arg0: string) => any }) =>
              !item.hasOwnProperty("optionId")
          );
          if (hasMissingOptionId) {
            setShowModal(hasMissingOptionId);
            return true;
          }
          handleNextCheckAnswer();
        } else {
          dispatch(updateActiveQuestion(activeQuestion + 1));
          setShowResult(false);
        }
      });
    }
  };

  const handleIconCClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row-reverse",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            margin: "0 auto",
            height: "calc(100vh - 71px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              maxWidth: "1230px",
              marginInline: "auto",
              minHeight: "29px",
              height: "fit-content",
            }}
          >
            {showResultMode === true || progressBarNavigationFlag ? (
              <div style={{ marginTop: "60px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    gap: "10px",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        xs: "105px",
                        sm: "105px",
                        md: "105px",
                        lg: "122px",
                      },
                      height: "80px",
                      borderRadius: "1px",
                    }}
                  >
                    <Stack
                      spacing={1}
                      py={2}
                      alignItems="center"
                      sx={{
                        borderRadius: "10px",
                        bgcolor: "white",
                        width: "inherit",
                      }}
                    >
                      <Typography
                        variant="h3"
                        color="info"
                        sx={{
                          fontSize: "24px",
                          fontWeight: 700,
                          color: "#3397DB",
                        }}
                      >
                        {container_data.total_examtime}
                      </Typography>
                      <Typography variant="caption" color="#AEB5BC">
                        <FormattedMessage id="time" />
                      </Typography>
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: {
                        xs: "105px",
                        sm: "105px",
                        md: "105px",
                        lg: "122px",
                      },
                      height: "80px",
                      border: "1px solid white",
                      borderRadius: "1px",
                    }}
                  >
                    <Stack
                      spacing={1}
                      py={2}
                      alignItems="center"
                      sx={{
                        borderRadius: "10px",
                        bgcolor: "white",
                        width: "inherit",
                      }}
                    >
                      <Typography
                        variant="h3"
                        color="info"
                        sx={{
                          fontSize: "24px",
                          fontWeight: 700,
                          color: "#3397DB",
                        }}
                      >
                        {total_score}
                      </Typography>
                      <Typography variant="caption" color="#AEB5BC">
                        <FormattedMessage id="gradePracticeSummary" />
                      </Typography>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: {
                        xs: "105px",
                        sm: "105px",
                        md: "105px",
                        lg: "122px",
                      },

                      height: "80px",
                      borderRadius: "1px",
                    }}
                  >
                    <Stack
                      spacing={1}
                      py={2}
                      alignItems="center"
                      sx={{
                        borderRadius: "10px",
                        bgcolor: "white",
                        width: "inherit",
                      }}
                    >
                      <Typography
                        variant="h3"
                        color="info"
                        sx={{
                          fontSize: "24px",
                          fontWeight: 700,
                          color: "#3397DB",
                        }}
                      >
                        {totalCorrectAnswer}
                        <span style={{ fontWeight: 400, fontSize: "12px" }}>
                          /{questions.length}
                        </span>
                      </Typography>
                      <Typography variant="caption" color="#AEB5BC">
                        <FormattedMessage id="amount-of-correct-answers" />
                      </Typography>
                    </Stack>
                  </Box>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Box>
          <AssignmentDialog openAssignmentSuccess={assignmentModal} />
          <SessionDialog openSessionSuccess={sessionModal} />
          {questions.map((item: any, index: any, array: any) => {
            return (
              <>
                <div>
                  {item.type.id === 1 && activeQuestion === index && (
                    <>
                      {isLoading ? (
                        <h2
                          style={{ paddingRight: "15px", paddingLeft: "15px" }}
                        >
                          Loading
                        </h2>
                      ) : (
                        <OpenTextBox
                          question={item.question}
                          onSubmit={handleAnswerSubmit}
                          question_id={item.id}
                          timesPerQuestion={examTime}
                          showResultMode={showResultMode}
                          solving_explanation={item.solving_explanation}
                          explanation_image={item.explanation_image}
                          score={item.points}
                        />
                      )}
                    </>
                  )}
                  {item.type.id === 2 && activeQuestion === index && (
                    <>
                      {isLoading ? (
                        <h2
                          style={{ paddingRight: "15px", paddingLeft: "15px" }}
                        >
                          Loading
                        </h2>
                      ) : (
                        <ImageAndText
                          question_id={item.id}
                          question={item.question}
                          option_images={item.option_images}
                          options={item.options}
                          image={item.image}
                          onSubmit={handleAnswerSubmit}
                          timesPerQuestion={examTime}
                          collection={item.collections}
                          is_completed={item.is_completed}
                          is_skipped_question={item.is_skipped_question}
                          solving_explanation={item.solving_explanation}
                          showResultMode={showResultMode}
                          horizontal={item.horizontal}
                          setIsCollapsed2={setIsCollapsed2}
                          explanation_image={item.explanation_image}
                          score={item.points}
                        />
                      )}
                    </>
                  )}
                  {item.type.id === 3 && activeQuestion === index && (
                    <>
                      {isLoading ? (
                        <h2
                          style={{ paddingRight: "15px", paddingLeft: "15px" }}
                        >
                          Loading
                        </h2>
                      ) : (
                        <TextImageWithQuestion
                          question={item.question}
                          option_images={item.option_images}
                          options={item.options}
                          image={item.image}
                          question_id={item.id}
                          onSubmit={handleAnswerSubmit}
                          timesPerQuestion={examTime}
                          collection={item.collections}
                          solving_explanation={item.solving_explanation}
                          showResultMode={showResultMode}
                          horizontal={item.horizontal}
                          setIsCollapsed2={setIsCollapsed2}
                          explanation_image={item.explanation_image}
                          score={item.points}
                        />
                      )}
                    </>
                  )}
                  {item.type.id === 4 && activeQuestion === index && (
                    <>
                      {isLoading ? (
                        <h2
                          style={{ paddingRight: "15px", paddingLeft: "15px" }}
                        >
                          Loading
                        </h2>
                      ) : (
                        <NumericBulletSingleChoice
                          question={item.question}
                          option_images={item.option_images}
                          options={item.options}
                          image={item.image}
                          question_id={item.id}
                          onSubmit={handleAnswerSubmit}
                          timesPerQuestion={examTime}
                          collection={item.collections}
                          solving_explanation={item.solving_explanation}
                          showResultMode={showResultMode}
                          horizontal={item.horizontal}
                          setIsCollapsed2={setIsCollapsed2}
                          explanation_image={item.explanation_image}
                          score={item.points}
                        />
                      )}
                    </>
                  )}
                  {item.type.id === 5 && activeQuestion === index && (
                    <LongText />
                  )}
                  {item.type.id === 6 && activeQuestion === index && (
                    <ImageQuestion />
                  )}
                  {item.type.id === 7 && activeQuestion === index && (
                    <>
                      {isLoading ? (
                        <h2
                          style={{ paddingRight: "15px", paddingLeft: "15px" }}
                        >
                          Loading
                        </h2>
                      ) : (
                        <EnglishLayout
                          question={item.question}
                          option_images={item.option_images}
                          options={item.options}
                          image={item.image}
                          question_id={item.id}
                          onSubmit={handleAnswerSubmit}
                          timesPerQuestion={examTime}
                          collection={item.collections}
                          solving_explanation={item.solving_explanation}
                          showResultMode={showResultMode}
                          horizontal={item.horizontal}
                          setIsCollapsed2={setIsCollapsed2}
                          type_id={item.type.id}
                          explanation_image={item.explanation_image}
                          score={item.points}
                        />
                      )}
                    </>
                  )}
                  {item.type.id === 8 && activeQuestion === index && (
                    <Quantitative />
                  )}
                  {item.type.id === 9 && activeQuestion === index && (
                    <VerbatimText />
                  )}
                  {item.type.id === 10 && activeQuestion === index && (
                    <>
                      {isLoading ? (
                        <h2
                          style={{ paddingRight: "15px", paddingLeft: "15px" }}
                        >
                          Loading
                        </h2>
                      ) : (
                        <EnglishLayout
                          question={item.question}
                          option_images={item.option_images}
                          options={item.options}
                          image={item.image}
                          question_id={item.id}
                          onSubmit={handleAnswerSubmit}
                          timesPerQuestion={examTime}
                          collection={item.collections}
                          solving_explanation={item.solving_explanation}
                          showResultMode={showResultMode}
                          horizontal={item.horizontal}
                          setIsCollapsed2={setIsCollapsed2}
                          type_id={item.type.id}
                          explanation_image={item.explanation_image}
                          score={item.points}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            );
          })}
          {showResult || progressBarNavigationFlag ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                  height: "70px",
                  width: ["-webkit-fill-available", "-moz-available"],
                  position: "fixed",
                  bottom: "0",
                  zIndex: "0",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "7A49B9",
                    my: "15px",
                    width: "152px",
                    height: "40px",
                    padding: "9px 22px",
                    borderRadius: "8px",
                  }}
                  disabled={activeQuestion == questions.length - 1}
                  onClick={() => handleNextCheckAnswer()}
                >
                  <FormattedMessage id="next-question" />
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #7A49B9",
                    color: "#7A49B9",
                    my: "15px",
                    marginRight: "10px",
                    height: "40px",
                    padding: "10px 30px",
                    borderRadius: "8px",
                  }}
                  onClick={handleSummaryPage}
                >
                  <FormattedMessage id="done-researching" />
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
        </div>
        {showResult === true || progressBarNavigationFlag ? (
          <>
            {isCollapsed2 === true ? (
              <>
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                    },
                    height: {
                      xs: "300px",
                      sm: "300px",
                      md: "100%",
                      lg: "100%",
                    },
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      height: "100%",
                    }}
                  >
                    <QuestionSideBar question_id={questions[activeQuestion]} />
                  </div>
                </Box>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      {showModal && (
        <ModalComponent
          isDialogOpen={true}
          onClose={closeModal}
          modal_data={modalSubmitData}
        />
      )}

      {allQuestionAttempt === true ? (
        <>
          <Box
            className={`${
              activeQuestion == 0 ? "question-option-mobile-4" : ""
            }`}
            sx={{
              backgroundColor: "#FFFFFF",
              boxShadow: "-4px 4px 4px 0px rgba(151, 151, 151, 0.25)",
              borderRadius: "0px 30px 30px 0px",
              height: "42px",
              width: "52px",
              position: "absolute",
              top: "78px",
              left: "0",
              textAlign: "center",
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={handleIconCClick}>
              <EditIconCollaps />
            </IconButton>
          </Box>
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            sx={{
              position: "fixed",
              top: "78px",
              right: "auto",
              left: "0 !important",
              height: "330px",
              width: { xs: "350px", md: "300px" },
              background: "#FFFFFF",
              borderRadius: "0 30px 30px 0",
              overflow: "hidden",
            }}
          >
            <div
              className="modal-content"
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
                borderRadius: "0 30px 30px 0",
              }}
            >
              <IconButton
                onClick={handleIconCClick}
                style={{ position: "absolute", left: "10px", top: "10px" }}
              >
                <EditIconCollapsModel />
              </IconButton>
              <ArrowRight
                onClick={handleCloseModal}
                style={{ position: "absolute", right: "10px", top: "15px" }}
              />

              <QuestionSideBar question_id={questions[activeQuestion]} />
            </div>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default QuizType;
