import React from "react";
import { Close } from "@mui/icons-material";
import { Box, Button, Container, Modal, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import OctopusDumbbell from "../../assets/images/octopus-dumbbell.svg";
import OctopusDumbbellCelebration from "../../assets/images/octopus-dumbbell-celebration.svg";
import { setOpenSessionSuccess } from "Redux/feature/StudentHome";

import { useAppDispatch } from "Redux/App/hooks";

const octopusModalView = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "787px",
  maxWidth: "calc(100% - 30px)",
  background: "#F8F9FA",
  border: "1px solid #D0D4D9",
  boxShadow: 24,
  pt: "5px",
  pb: "30px",
  borderRadius: { xs: "8px", md: "30px" },
  minWidth: "1px",
};
const ModalCloseButtonRelative = {
  minWidth: "1px",
  padding: "8px 0",
};
type Props = { openSessionSuccess: any };

const SessionDialog = ({ openSessionSuccess }: Props) => {
  const dispatch = useAppDispatch();
  // const openSessionSuccess: any = useAppSelector(
  //   (state) => state.StudentsHomeData.openSessionSuccess
  // );
  const handleCloseSessionSuccess = () =>
    dispatch(setOpenSessionSuccess(false));
  return (
    <div>
      <Modal
        open={openSessionSuccess}
        onClose={handleCloseSessionSuccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: "101" }}
      >
        <Box
          sx={octopusModalView}
          className="modal-parent octopus-session-modal"
          style={{ overflow: "hidden" }}
        >
          <img
            src={OctopusDumbbellCelebration}
            alt="icon"
            className="session-celebration-graphics"
            style={{
              width: "100%",
              maxWidth: "500px",
              position: "absolute",
              top: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: "-1",
            }}
          />
          <Container sx={{ px: "0" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button sx={ModalCloseButtonRelative} className="modal-close">
                <Close onClick={handleCloseSessionSuccess} />
              </Button>
            </Box>
            <Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  maxWidth: { xs: "233px", sm: "320px", md: "400px" },
                  m: { xs: "30px auto 15px", sm: "40px auto 0" },
                }}
              >
                <img
                  src={OctopusDumbbell}
                  alt="icon"
                  className="octopus-dumbbell"
                  style={{
                    width: "100%",
                    maxWidth: "310px",
                    marginBottom: "40px",
                  }}
                />
              </Box>
              <Box sx={{ px: "15px" }}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: "24px", lg: "32px" },
                    textAlign: "center",
                  }}
                >
                  <FormattedMessage id="session-success-modal-title" />
                </Typography>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  <FormattedMessage id="session-success-modal-text" />
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      </Modal>
    </div>
  );
};

export default SessionDialog;
