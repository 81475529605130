import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  GetChapterExamQuestion,
  GetChapterExamAccordion,
  setPrincipleAccordionNavigationFlag,
  updateSelectedChapter,
} from "Redux/feature/ChapterExamSlice";
import { Box } from "@mui/joy";
import { useSearchParams } from "react-router-dom";
import {
  FetchQuestions,
  GetAllSubmittedQuestion,
  setNumericBar,
  setProgressBarFlag,
  setProgressBarNavigation,
  setQuizQuestionComplete,
  setResearchTab,
  updateActiveQuestion,
} from "Redux/feature/Questions";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "./cookieUtils";

type Props = {
  assignmentId: any;
};

interface ParentItem {
  id: number;
  name: string;
  total_questions: string;
  total_true_answer: string;
  true_percentage: string;
  children: ChildItem[];
  questions_data: QuestionData[];
}
interface QuestionData {
  chapter: string;
  chapter_id: number;
  questions: any[];
}

interface ChildItem {
  id: number;
  name: string;
  total_questions: string;
  children: ChildItem[];
  true_percentage: string;
  total_true_answer: string;
  questions_data: QuestionData[];
}

interface AccordionItem {
  parent: ParentItem;
}

const AccordionForChapterExam = ({ assignmentId }: Props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const user_id = getCookie("id");

  const userType = getCookie("user_type");

  const studentId = getCookie("studentId_teacherSide");
  const containerId: any = searchParams.get("container_type_id");
  const assignment_id = searchParams.get("assignment_id");
  const defaultCourseId = getCookie("default_course_id");

  const dispatch = useAppDispatch();
  const isLoading: any = useAppSelector(
    (state) => state.ChapterExamSlice?.isLoading
  );
  let selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  const navigatingFromChapterExamT: boolean = useAppSelector(
    (state) => state.ChapterExamSlice?.navigatingFromChapterExamT
  );
  const assignment_type: number = useAppSelector(
    (state) => state.ChapterExamSlice?.assignment_type
  );

  const navigatingFromChapterExamF: boolean = useAppSelector(
    (state) => state.ChapterExamSlice?.navigatingFromChapterExamF
  );

  useEffect(() => {
    if (navigatingFromChapterExamT && navigatingFromChapterExamF && user_id) {
      dispatch(
        GetChapterExamAccordion({
          user_id: userType === "Student" ? user_id : studentId,
          assignment_id: assignmentId,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          ...((containerId == "10"||containerId == "11") && { type: "Online Exam" }),
        })
      );
    }
  }, [dispatch, navigatingFromChapterExamT, studentId, user_id, assignmentId]);

  useEffect(() => {
    if (!navigatingFromChapterExamT && !navigatingFromChapterExamF && user_id) {
      dispatch(
        GetChapterExamAccordion({
          user_id: userType === "Student" ? user_id : studentId,
          assignment_id: assignmentId,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          ...((containerId == "10"||containerId == "11") && { type: "Online Exam" }),
        })
      );
    }
  }, [dispatch, navigatingFromChapterExamT, studentId, user_id, assignmentId]);

  const chapterExamAccordionData = useAppSelector(
    (state) => state?.ChapterExamSlice.getChapterExamAccordion.children
  );

  const chapterExamAccordionDataGrandParentData = useAppSelector(
    (state) => state?.ChapterExamSlice.getChapterExamAccordion.parent
  );  

  const modifiedAccordion = chapterExamAccordionData.map((item) => ({
    ...item,
    parent: {
      ...item.parent,
      children: item.parent.children || [],
    },
  }));

  const lastChildIds = modifiedAccordion.map((item) =>
    item.parent.children.length > 0
      ? item.parent.children[item.parent.children.length - 1].id
      : null
  );
  const [openAccordions, setOpenAccordions] = useState<number[]>(
    lastChildIds.filter((id) => id !== null) as number[]
  );
  const [showChildQuestionData, setShowChildQuestionData] = useState<number[]>(
    []
  );
  const [isGrandParentOpen, setIsGrandParentOpen] = useState<boolean>(false);
  const location = useLocation();

  const handleClick = async (data: any, questionData: any, index: number) => {
    const basePath = "/home";
    const resultPath = `${basePath}/student-management/student-exam/result`;
    const learningPath = `${basePath}/learning-management`;

    if (location.pathname === resultPath) {
      return;
    }

    if (location.pathname.startsWith(learningPath)) {
      if ((assignment_type == 4||assignment_type == 5) && user_id) {
        dispatch(
          FetchQuestions({
            user_id: user_id,
            container_id: data?.chapter_id,
            assignment_id: assignmentId,
          })
        ).then((res) => {
          dispatch(
            GetAllSubmittedQuestion({
              user_id: user_id,
              assignment_id: assignmentId,
              container_id: data?.chapter_id,
            })
          );
          dispatch(setProgressBarNavigation(true));
          dispatch(setNumericBar(true));
          dispatch(setResearchTab(false));

          dispatch(setProgressBarFlag(true));
          dispatch(updateActiveQuestion(index - 1));
          dispatch(setQuizQuestionComplete(true));
          let path;
          if(assignment_type == 4){
           path = `/question-pages?container_type_id=10&container_id=${data?.chapter_id}&assignment_id=${questionData.data.answer.exam_id}&isResearch=true`;

          }else{
             path = `/question-pages?container_type_id=11&container_id=${data?.chapter_id}&assignment_id=${questionData.data.answer.exam_id}&isResearch=true`;

          }

          dispatch(updateSelectedChapter(data?.chapter_id));

          navigate(path, { state: { isResearch: true } });
        });
      } else {
        const path = `/question-pages?container_type_id=5&container_id=${data?.chapter_id}&assignment_id=${questionData.data.answer.exam_id}&isResearch=true`;

        dispatch(updateSelectedChapter(data?.chapter_id));

        navigate(path, { state: { isResearch: true } });

        dispatch(setProgressBarFlag(true));
        dispatch(setNumericBar(true));
        dispatch(updateActiveQuestion(index - 1));
        dispatch(setQuizQuestionComplete(true));
        dispatch(setPrincipleAccordionNavigationFlag(false));
      }
    } else {
      dispatch(updateSelectedChapter(data?.chapter_id));
      if ((containerId == "10"||containerId == "11") && assignment_id) {
        dispatch(
          FetchQuestions({
            user_id: user_id ? user_id : null,
            container_id: data?.chapter_id,
            assignment_id: assignment_id,
          })
        ).then((res) => {
          dispatch(
            GetAllSubmittedQuestion({
              user_id: user_id,
              assignment_id: assignment_id,
              container_id: data?.chapter_id,
            })
          );
          dispatch(setProgressBarNavigation(true));
          dispatch(setNumericBar(true));
          dispatch(setResearchTab(false));
        });
      } else {
        dispatch(
          GetChapterExamQuestion({
            user_id: user_id ? user_id : null,
            assignment_id: questionData.data.answer.exam_id,
          })
        );
        dispatch(
          GetAllSubmittedQuestion({
            user_id: user_id,
            assignment_id: questionData.data.answer.exam_id,
            container_id: data.chapter_id,
          })
        );
      }
      dispatch(setProgressBarFlag(true));
      dispatch(setNumericBar(true));
      dispatch(updateActiveQuestion(index - 1));
      dispatch(setQuizQuestionComplete(true));
      dispatch(setPrincipleAccordionNavigationFlag(false));
    }
  };

  const toggleAccordion = (parentId: number) => {
    const isParentOpen = openAccordions.includes(parentId);
    if (isParentOpen) {
      setOpenAccordions(openAccordions.filter((id) => id !== parentId));
      setShowChildQuestionData([]);
    } else {
      setOpenAccordions([...openAccordions, parentId]);
      setShowChildQuestionData([]);
    }

    const parentItem = modifiedAccordion.find(
      (item) => item.parent.id === parentId
    );
    if (!isParentOpen && parentItem) {
      setShowChildQuestionData([]);

      closeChildAccordions(parentItem.parent.children);
    }
  };

  const closeChildAccordions = (children: ChildItem[]) => {
    children.forEach((child) => {
      setShowChildQuestionData([]);
      setOpenAccordions((prevState) =>
        prevState.filter((id) => id !== child.id)
      );
      if (child.children && child.children.length > 0) {
        closeChildAccordions(child.children);
        setShowChildQuestionData([]);
      }
    });
  };

  const isAccordionOpen = (parentId: number) => {
    return openAccordions.includes(parentId);
  };
  const renderAccordion = (item: AccordionItem, level: number) => {
    const isParentOpen = isAccordionOpen(item.parent.id);
    const paddingRights =
      level === 0 ? "65px" : level === 1 ? "120px" : `${50 * level}px`;
    const childPaddingRights =
      level === 0 ? "120px" : level === 1 ? "175px" : `${55 * level}px`;

    const isToggleDisabled = item?.parent?.questions_data?.length === 0;
    // const isToggleDisabled = item?.parent?.children?.length === 0;

    const toggleShowChildQuestionData = (childId: number) => {
      setShowChildQuestionData((prevState) => {
        const isChildShown = prevState.includes(childId);
        if (isChildShown) {
          return prevState.filter((id) => id !== childId);
        } else {
          return [...prevState, childId];
        }
      });
    };

    return (
      <Box className="custom-accordion-research">
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            paddingLeft: { xs: "15px", md: "35px" },
            paddingRight: { xs: "15px", md: `${paddingRights}` },
            border: "1px solid #DFE2E5",
            borderTop: "0",
            borderLeft: "0",
            borderRight: "0",
            // backgroundColor: isParentOpen
            //   ? parentColor
            //   : isToggleDisabled
            //   ? "#dadfe2 "
            //   : "#FFF",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
            }}
            onClick={() => !isToggleDisabled && toggleAccordion(item.parent.id)}
          >
            <div
              style={{
                color: "#7A49B9",
                background: "#FFFFFF",
                width: "35px",
                height: "35px",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                verticalAlign: "center",
                boxShadow: "0px 5px 12px rgba(8, 15, 52, 0.1)",
              }}
            >
              {isToggleDisabled ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="22"
                  viewBox="0 0 24 22"
                  fill="none"
                >
                  <path
                    d="M5.15674 11H19.1567"
                    stroke="#7A49B9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : isParentOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="22"
                  viewBox="0 0 24 22"
                  fill="none"
                >
                  <path
                    d="M5.15674 11H19.1567"
                    stroke="#7A49B9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="22"
                  viewBox="0 0 24 22"
                  fill="none"
                >
                  <path
                    d="M12.1567 4V18"
                    stroke="#7A49B9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.15674 11H19.1567"
                    stroke="#7A49B9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </div>
            <p style={{ margin: "0", fontSize: "18px", width: "150px" }}>
              {item.parent.name}
            </p>
            <span style={{ fontSize: "16px" }}>
              ({item.parent.total_questions} / {item.parent.total_true_answer})
            </span>
          </div>
          <div style={{ paddingTop: "7px", fontSize: "16px" }}>
            {item.parent.true_percentage}
          </div>
        </Box>
        {isParentOpen && (
          <div style={{}}>
            {item.parent.children &&
              item.parent.children.length > 0 &&
              item.parent.children.map((child) => (
                <div key={child.id} style={{ padding: "" }}>
                  {child.children && child.children.length >= 0 ? (
                    renderAccordion({ parent: child }, level + 1)
                  ) : (
                    <Box
                      sx={{
                        cursor: "pointer",
                        padding: "10px",
                        paddingLeft: { xs: "15px", md: "35px" },
                        paddingRight: {
                          xs: "15px",
                          md: childPaddingRights,
                        },
                        border: "1px solid #DFE2E5",
                        borderTop: "0",
                        borderLeft: "0",
                        borderRight: "0",
                        backgroundColor: "#f8f9fa",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              gap: "10px",
                            }}
                            onClick={() =>
                              toggleShowChildQuestionData(child.id)
                            }
                          >
                            <div
                              style={{
                                color: "#7A49B9",
                                background: "#FFFFFF",
                                width: "35px",
                                height: "35px",
                                borderRadius: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                verticalAlign: "center",
                                boxShadow: "0px 5px 12px rgba(8, 15, 52, 0.1)",
                              }}
                            >
                              {isToggleDisabled ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="22"
                                  viewBox="0 0 24 22"
                                  fill="none"
                                >
                                  <path
                                    d="M5.15674 11H19.1567"
                                    stroke="#7A49B9"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              ) : showChildQuestionData.includes(child.id) ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="22"
                                  viewBox="0 0 24 22"
                                  fill="none"
                                >
                                  <path
                                    d="M5.15674 11H19.1567"
                                    stroke="#7A49B9"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="22"
                                  viewBox="0 0 24 22"
                                  fill="none"
                                >
                                  <path
                                    d="M12.1567 4V18"
                                    stroke="#7A49B9"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M5.15674 11H19.1567"
                                    stroke="#7A49B9"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              )}
                            </div>
                            <p
                              style={{
                                margin: "0",
                                fontSize: "18px",
                                width: "150px",
                              }}
                            >
                              {child?.name}
                            </p>
                            <span style={{ fontSize: "16px" }}>
                              ({child?.total_questions} /{" "}
                              {child?.total_true_answer})
                            </span>
                          </div>
                          <div style={{ fontSize: "16px" }}>
                            {child?.true_percentage}
                          </div>
                        </Box>
                        {/* child questionData */}
                        {showChildQuestionData.includes(child.id) && (
                          <Box
                            sx={{
                              color: "#333333",
                              display: "flex",
                              flexDirection: "row",
                              paddingRight: { md: "50px" },
                              // parseFloat(paddingRights) + parseFloat("30px"),
                              gap: "10px",
                            }}
                          >
                            <div>
                              {child.questions_data?.map((data, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    justifyContent: "center",
                                    alignContent: "center",
                                    verticalAlign: "center",
                                    gap: "10px",
                                    width: "fit-content",
                                    padding: {
                                      xs: "10px 0px 10px 0px",
                                      md: "10px 0px 10px 15px",
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      fontSize: "16px",
                                      // width: { md: "90px" },
                                    }}
                                  >
                                    {data.chapter}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      width: "auto",
                                      justifyContent: "flex-start",
                                      gap: "6px",
                                    }}
                                  >
                                    {data.questions.map(
                                      (questionData, questionIndex) => (
                                        <div
                                          onClick={() =>
                                            handleClick(
                                              data,
                                              questionData,
                                              questionData?.data
                                                ?.question_number
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                            width: "28px",
                                            height: "28px",
                                            borderRadius: "5px",
                                            backgroundColor: "white",
                                            color: ` ${
                                              questionData?.data?.answer
                                                ?.is_skipped_question === 1
                                                ? "#AEB5BC"
                                                : questionData?.data?.answer
                                                    ?.is_correct_answer === 0
                                                ? "#E74C3C"
                                                : "#33CD75"
                                            } `,

                                            border: "1px solid  #AEB5BC",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",

                                            gap: "10px",
                                            // marginBottom: "6px",
                                            padding: "auto",
                                            fontSize: "14px",
                                            fontWeight: "700",
                                          }}
                                          key={questionIndex}
                                        >
                                          {questionData?.data?.question_number}{" "}
                                        </div>
                                      )
                                    )}
                                  </Box>
                                </Box>
                              ))}
                            </div>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </div>
              ))}
          </div>
        )}
      </Box>
    );
  };

  return (
    <>
      {isLoading ? (
        <h2 style={{ paddingRight: "15px", paddingLeft: "15px" }}>Loading</h2>
      ) : (
        <Box
          sx={{
            borderRadius: "20px",
            // border: "1px solid  #DFE2E5",
            boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.05)",
            overflow: "hidden",
          }}
        >
          {chapterExamAccordionDataGrandParentData?.name ? (
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
                paddingLeft: { xs: "15px", md: "35px" },
                paddingRight: { xs: "15px", md: "35px" },
                border: "1px solid #DFE2E5",
                borderTop: "0",
                borderLeft: "0",
                borderRight: "0",
              // backgroundColor: isParentOpen
              //   ? parentColor
              //   : isToggleDisabled
              //   ? "#dadfe2 "
              //   : "#FFF",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                }}
              onClick={(x) => {
                  setIsGrandParentOpen((prev) => !prev);
                  setOpenAccordions([]);
                  setShowChildQuestionData([]);
                }}
              >
                <div
                  style={{
                    color: "#7A49B9",
                    background: "#FFFFFF",
                    width: "35px",
                    height: "35px",
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    verticalAlign: "center",
                    boxShadow: "0px 5px 12px rgba(8, 15, 52, 0.1)",
                  }}
                >
                  {isGrandParentOpen === true ? (
                  <>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="22"
                      viewBox="0 0 24 22"
                      fill="none"
                    >
                      <path
                        d="M5.15674 11H19.1567"
                        stroke="#7A49B9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </>
                  ) : (
                  <>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="22"
                      viewBox="0 0 24 22"
                      fill="none"
                    >
                      <path
                        d="M12.1567 4V18"
                        stroke="#7A49B9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.15674 11H19.1567"
                        stroke="#7A49B9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </>
                  )}
                </div>
                <p style={{ margin: "0", fontSize: "18px", width: "150px" }}>
                  {chapterExamAccordionDataGrandParentData?.name}
                </p>
                <span style={{ fontSize: "16px" }}>
                  ({chapterExamAccordionDataGrandParentData?.total_questions} /{" "}
                  {chapterExamAccordionDataGrandParentData?.total_true_answer})
                </span>
              </div>
              <div style={{ paddingTop: "7px", fontSize: "16px" }}>
                {chapterExamAccordionDataGrandParentData?.true_percentage}
              </div>
            </Box>
          ) : (
            <>
              {modifiedAccordion.map((item) => renderAccordion(item, 0))}
            </>
          )}
          {isGrandParentOpen &&
            modifiedAccordion.map((item) => renderAccordion(item, 0))}
        </Box>
      )}
    </>
  );
  
};

export default AccordionForChapterExam;
