import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";

interface getEssayList {
  student_id:string;
  per_page:number;
  page:number;
  course_id:string;
}
interface getConnectionName {
  source_id:string;
  student_id:string;
  course_id:string;
}
interface fetchDeleteEssay {
  essay_id:number;
  student_id:string;
}
interface getProductList {
  teacher_id:string;
}
interface allStudentEssayList {
  course_id:string;
  teacher_id:string | null;
}
interface submitEssay {
  student_id:string;
  teacher_id:string;
  student_essay_id:string | undefined;
  language_score:string;
  content_score:string;
  conservation_notes:any[];
  conservation_notes_details:string;
  improvement_notes:any[];
  improvement_notes_details:string;
  general_comment:string;
  reason_for_return:string;
  status:string;
  course_id:string;
}
interface updateStatusOfEssay {
  student_essay_id:string | undefined;
  teacher_id:string;
  student_id:string;
  status:string;
  course_id:string;
}
interface essayListData {
  teacher_id: string;
  course_id?: string;
  student_id?: string | null;
  search?: string;
  product_id?: string | number | undefined;
}
interface essayDetailsTeacher {
  essay_id: string | number | undefined;
  student_id?: string;
  teacher_id?: string;
  course_id?: string;
  product_id?: string | number;
}


export const GetEssayList = createAsyncThunk(
  "EssayModuleSlice/get-essay-list",
  async (data: getEssayList) => {
    try {
      const res = await axiosClient.get(
        `get-essay-list?student_id=${data.student_id}&per_page=${data.per_page}&page=${data.page}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const GetConnectionSource = createAsyncThunk(
  "EssayModuleSlice/get-connection-source",
  async (data: any) => {
    try {
      const res = await axiosClient.get(`get-connection-sourse?course_id=${data.course_id}`);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const GetConnectionName = createAsyncThunk(
  "EssayModuleSlice/get-connection-name",
  async (data: getConnectionName) => {
    try {
      const res = await axiosClient.get(
        `get-connection-name?source_id=${data.source_id}&student_id=${data.student_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const FetchDeleteEssay = createAsyncThunk(
  "EssayModuleSlice/delete-essay",
  async (data: fetchDeleteEssay) => {
    try {
      const res = await axiosClient.put(
        `delete-essay?essay_id=${data.essay_id}&student_id=${data.student_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const GetTeacherEssayList = createAsyncThunk(
  "EssayModuleSlice/get-student-essay",
  async (data: essayListData) => {
    const { teacher_id, course_id, student_id, search, product_id } = data;
    const queryParams = new URLSearchParams({
      teacher_id,
      ...(course_id && { course_id }),
      ...(product_id !== undefined && { product_id: product_id.toString() }),
      ...(student_id !== null && student_id !== undefined && { student_id }),
      ...(search && { search }),
    });
    try {
      const res = await axiosClient.get(`get-student-essay?${queryParams}`);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const SaveEssayData = createAsyncThunk(
  "EssayModuleSlice/save-essay-data",

  async (data: any) => {
    try {
      const res = await axiosClient.post("save-essay-data", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const EssayDetailsTeacher = createAsyncThunk(
  "EssayModuleSlice/EssayDetailsTeacher",
  async (data: essayDetailsTeacher) => {
    let url = `get-essay?student_essay_id=${data.essay_id}`;

    if (data.student_id !== undefined) {
      url += `&student_id=${data.student_id}`;
    }
    if (data.teacher_id !== undefined) {
      url += `&teacher_id=${data.teacher_id}`;
    }

    if (data.course_id !== undefined) {
      url += `&course_id=${data.course_id}`;
    }

    if (data.product_id !== undefined) {
      url += `&product_id=${data.product_id}`;
    }
    try {
      const res = await axiosClient.get(url);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const submitEssay = createAsyncThunk(
  "EssayModuleSlice/submitEssay",
  async (data: submitEssay) => {
    try {
      const res = await axiosClient.post("submit-essay-data", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const GetProductList = createAsyncThunk(
  "EssayModuleSlice/getProductList",
  async (data: getProductList) => {
    try {
      const res = await axiosClient.get(
        `get-product-list?teacher_id=${data.teacher_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const UpdateStatusOfEssay = createAsyncThunk(
  "EssayModuleSlice/updateStatus",
  async (data: updateStatusOfEssay) => {
    try {
      const res = await axiosClient.post("update-essay-status", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const allStudentEssayList = createAsyncThunk(
  "EssayModuleSlice/studentEssayList",
  async (data: allStudentEssayList) => {
    try {
      const res = await axiosClient.get(
        `get-course-wise-student-essay-list?course_id=${data.course_id}&teacher_id=${data.teacher_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

interface InitialStage {
  getEssayDetailsTeacher: any[];
  essayList: any[];
  essayListTotal: number;
  essayData: any[];
  teacherEssayList: {
    counters: {
      waiting_to_be_checked: number;
      reviewed_this_month: number;
    };
    data: any[];
    othersData: {
      teacher_name: string;
      student_name: string;
    };
    notesListing: {
      conservation_notes: {
        1: string;
      };
      improvement_notes: {
        1: string;
      };
    };
    check_connection_id: number;
    last_check_student_id: string;
    notesDetails: {
      conservation_notes_details: [
        {
          description: string;
        }
      ];
      improvement_notes_details: [
        {
          description: string;
        }
      ];
    };
  };
  productListForEssay: [
    {
      id: number;
      name: string;
    }
  ];
  allStudentEssayList: {
    title_row: [
      {
        essay_name: string;
        essay_id: number;
      }
    ];
    data: [
      {
        student_name: string;
        student_id: string;
        essay_data: [
          {
            language_score: string;
            content_score: string;
            essay_id: number;
          }
        ];
      }
    ];
  };

  submitEssay: any[];
  connectionSource: any[];
  connectionName: any[];
  deleteEssay: any[];
  isLoading: boolean;
  error: any;
}

const initialState: InitialStage = {
  getEssayDetailsTeacher: [],
  essayList: [],
  essayListTotal: 10,
  essayData: [],
  teacherEssayList: {
    counters: {
      waiting_to_be_checked: 0,
      reviewed_this_month: 0,
    },
    data: [],
    othersData: {
      teacher_name: "",
      student_name: "",
    },
    notesListing: {
      conservation_notes: {
        1: "",
      },
      improvement_notes: {
        1: "",
      },
    },
    notesDetails: {
      conservation_notes_details: [
        {
          description: "",
        },
      ],
      improvement_notes_details: [
        {
          description: "",
        },
      ],
    },
    check_connection_id: 0,
    last_check_student_id: "",
  },
  productListForEssay: [
    {
      id: 0,
      name: "",
    },
  ],
  allStudentEssayList: {
    title_row: [
      {
        essay_name: "",
        essay_id: 0,
      },
    ],
    data: [
      {
        student_name: "",
        student_id: "",
        essay_data: [
          {
            language_score: "",
            content_score: "",
            essay_id: 0,
          },
        ],
      },
    ],
  },
  submitEssay: [],
  connectionSource: [],
  connectionName: [],
  deleteEssay: [],
  isLoading: false,
  error: null,
};

const EssayModuleSlice = createSlice({
  name: "EssayModuleSlice",
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(GetEssayList.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetEssayList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          essayList: action.payload.data.data,
          essayListTotal: action.payload.data.total,
        };
      })
      .addCase(GetEssayList.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(SaveEssayData.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(SaveEssayData.fulfilled, (state, action) => {
        return {
          ...state,
          essayData: action.payload.data,
          isLoading: true,
        };
      })
      .addCase(SaveEssayData.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetTeacherEssayList.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetTeacherEssayList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          teacherEssayList: action.payload,
        };
      })
      .addCase(GetTeacherEssayList.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetConnectionSource.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetConnectionSource.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          connectionSource: action.payload.connection_source,
        };
      })
      .addCase(GetConnectionSource.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetConnectionName.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetConnectionName.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          connectionName: action.payload.connection_source,
        };
      })
      .addCase(GetConnectionName.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchDeleteEssay.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchDeleteEssay.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          deleteEssay: action.payload,
        };
      })
      .addCase(FetchDeleteEssay.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(EssayDetailsTeacher.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(EssayDetailsTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          getEssayDetailsTeacher: action.payload,
        };
      })
      .addCase(EssayDetailsTeacher.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(submitEssay.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(submitEssay.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          submitEssayData: action.payload,
        };
      })
      .addCase(submitEssay.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetProductList.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetProductList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          productListForEssay: action.payload.data,
        };
      })
      .addCase(GetProductList.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(UpdateStatusOfEssay.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(UpdateStatusOfEssay.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(UpdateStatusOfEssay.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(allStudentEssayList.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(allStudentEssayList.fulfilled, (state, action) => {
        return {
          ...state,
          allStudentEssayList: action.payload.data,
          isLoading: false,
        };
      })
      .addCase(allStudentEssayList.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      });
  },
});

export default EssayModuleSlice.reducer;
