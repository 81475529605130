import React from "react";
import { Box, CircularProgress } from "@mui/material";

const LoadingFallback = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      marginTop: "64px",
      transition: "opacity 1s ease-in-out",
    }}
  >
    <CircularProgress />
  </Box>
);

export default LoadingFallback;
