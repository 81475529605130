import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";
import { UserDataInfo } from "../../types/UserInfo";
interface fetchClientLogin {
  user_id: string;
  password: string | null | FormDataEntryValue;
}
interface fetchSendOtp {
  username: string;
}
interface fetchVerifyOtp {
  username: string;
  otp: string | FormDataEntryValue | null;
}
interface fetchClientSetPassword {
  otp_verified_token: string;
  username: string;
  password: string | FormDataEntryValue | null;
}
interface fetchCheckUserLogin {
  workerId: string;
}

export const FetchClientLogin = createAsyncThunk(
  "UserAuth/clientLogin",
  async (data: fetchClientLogin) => {
    try {
      const res = await axiosClient.post("client-login", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchSendOtp = createAsyncThunk(
  "UserAuth/sendOtp",
  async (data: fetchSendOtp) => {
    try {
      const res = await axiosClient.post("send-otp", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchVerifyOtp = createAsyncThunk(
  "UserAuth/verifyOtp",
  async (data: fetchVerifyOtp) => {
    try {
      const res = await axiosClient.post("verify-otp", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchClientSetPassword = createAsyncThunk(
  "UserAuth/clientSetPassword",
  async (data: fetchClientSetPassword) => {
    try {
      const res = await axiosClient.post("client-set-password", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchCheckUserLogin = createAsyncThunk(
  "UserAuth/checkUserLogin",
  async (data: fetchCheckUserLogin) => {
    try {
      const res = await axiosClient.post("check-user-login", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

const initialState = {
  clientLoginIdle: "loading",
  workerId: "",
  sendOtpIdle: "success",
  verifyOtpIdle: "success",
  clientSetPasswordIdle: "success",
  checkUserLoginIdle: "success",
  id: "",
  oTPVerifiedToken: "",
  is_verified: 0,
  user_data: [],
  user_loginData: null as null | UserDataInfo,
  message: "",
  isFirstLogin: false,
  error: null as string | null,
};

const UserAuth = createSlice({
  name: "UserAuth",
  initialState,
  reducers: {
    updateWorkerId: (state, action) => {
      return {
        ...state,
        workerId: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(FetchClientLogin.pending, (state, action) => {
        return {
          ...state,
          clientLoginIdle: "pending",
          id: "",
        };
      })
      .addCase(FetchClientLogin.fulfilled, (state, { payload }) => {
        return {
          ...state,
          clientLoginIdle: "success",
          id: payload.data.id,
          user_data: payload.data,
          user_loginData: payload.data,
          message: payload.message,
        };
      })
      .addCase(FetchClientLogin.rejected, (state, { payload }) => {
        return {
          ...state,
          clientLoginIdle: "rejected",
          id: "",
        };
      })
      .addCase(FetchSendOtp.pending, (state, { payload }) => {
        return {
          ...state,
          sendOtpIdle: "pending",
        };
      })
      .addCase(FetchSendOtp.fulfilled, (state, { payload }) => {
        return {
          ...state,
          sendOtpIdle: "success",
          message: payload.message,
        };
      })
      .addCase(FetchSendOtp.rejected, (state, { payload }) => {
        return {
          ...state,
          sendOtpIdle: "rejected",
        };
      })
      .addCase(FetchVerifyOtp.pending, (state, { payload }) => {
        return {
          ...state,
          verifyOtpIdle: "pending",
        };
      })
      .addCase(FetchVerifyOtp.fulfilled, (state, { payload }) => {
        return {
          ...state,
          verifyOtpIdle: "success",
          oTPVerifiedToken: payload.data.otp_verified_token,
          message: payload.message,
        };
      })
      .addCase(FetchVerifyOtp.rejected, (state, { payload }) => {
        return {
          ...state,
          verifyOtpIdle: "rejected",
        };
      })
      .addCase(FetchClientSetPassword.pending, (state, { payload }) => {
        return {
          ...state,
          ClientSetPasswordIdle: "pending",
        };
      })
      .addCase(FetchClientSetPassword.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ClientSetPasswordIdle: "success",
          message: payload.message,
        };
      })
      .addCase(FetchClientSetPassword.rejected, (state, { payload }) => {
        return {
          ...state,
          ClientSetPasswordIdle: "rejected",
        };
      })
      .addCase(FetchCheckUserLogin.pending, (state, action) => {
        return {
          ...state,
          checkUserLoginIdle: "pending",
        };
      })
      .addCase(FetchCheckUserLogin.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isFirstLogin: payload.data.is_first_login,
          message: payload.message,
          checkUserLoginIdle: "success",
        };
      })
      .addCase(FetchCheckUserLogin.rejected, (state, action) => {
        const error = action.error
          ? action.error.message
          : "Unknown error occurred";
        return {
          ...state,
          checkUserLoginIdle: "rejected",
          message: error as string,
        };
      });
  },
});

export const { updateWorkerId } = UserAuth.actions;

export default UserAuth.reducer;
