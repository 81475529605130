import React, { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import NoPageFound from "views/common/NoPageFound";
import ProtectedRoute from "./ProtectedRoutes";
import Header from "views/student/Header";
import Footer from "views/common/Footer";
import LoadingFallback from "views/common/LoadingFallback";
import QuestionPages from "views/QuestionPage/Index";
import PracticeSummaryQuestion from "views/Questions/PracticeSummaryQuestion";

// Lazy load components
const Notifications = lazy(
  () => import("../views/Notifications/Notifications")
);
const StudentHome = lazy(() => import("../views/Students/StudentHome"));
const Tools = lazy(() => import("../views/tools/Tools"));
const ToolsVocabulary = lazy(() => import("../views/tools/ToolsVocabulary"));
const RegisterPage = lazy(() => import("../views/on-bording/RegisterPage"));
const Daybook = lazy(() => import("../views/daybook/DayBook"));
const LearningManagement = lazy(
  () => import("views/learning-management/LearningManagement")
);
const AddTestPage = lazy(() => import("views/learning-management/AddTestPage"));
const EditPersonalDetails = lazy(
  () => import("views/student-management/EditPersonalDetails")
);
const EditStudentProfile = lazy(
  () => import("views/student-management/EditStudentProfile")
);
const EditPsychometricDetails = lazy(
  () => import("views/student-management/EditPsychometricDetails")
);
const EditAmirantDetials = lazy(
  () => import("views/student-management/EditAmiratDetails")
);

const MainRoutes = {
  path: "/",
  element: <Outlet />,
  children: [
    {
      path: "*",
      element: <NoPageFound />,
    },
    {
      path: "/on-boarding",
      element: <RegisterPage />,
    },
    {
      path: "/student-home",
      element: (
        <ProtectedRoute user_type={"Student"} title="Student Home">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <StudentHome />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/notifications",
      element: (
        <ProtectedRoute user_type={"Student"} title="Notification">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <Notifications />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/tools/roboclock",
      element: (
        <ProtectedRoute user_type={"Student"} title="Tools">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <Tools />
          </Suspense>
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/tools/vocabulary",
      element: (
        <ProtectedRoute user_type={"Student"} title="Tools">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <ToolsVocabulary />
          </Suspense>
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/daybook",
      element: (
        <ProtectedRoute user_type={"Student"} title="Diary">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <Daybook />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/learning-management",
      element: (
        <ProtectedRoute user_type={"Student"} title="Learning Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <LearningManagement />
          </Suspense>
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/edit-personal-detail",
      element: (
        <ProtectedRoute user_type={"Student"} title="Student Profile">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <EditPersonalDetails />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/edit-psychometric-detail",
      element: (
        <ProtectedRoute user_type={"Student"} title="Student Profile">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <EditPsychometricDetails />
          </Suspense>
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/edit-amirant-detail",
      element: (
        <ProtectedRoute user_type={"Student"} title="Student Profile">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <EditAmirantDetials />
          </Suspense>
        </ProtectedRoute>
      ),
    },

    {
      path: "/home/edit-student-profile/:id",
      element: (
        <ProtectedRoute user_type={"Student"} title="Student Profile">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <EditStudentProfile />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/learning-management/exam-analysis",
      element: (
        <ProtectedRoute user_type={"Student"} title="Learning Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <LearningManagement />
          </Suspense>
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/learning-management/exam-analysis/add-test",
      element: (
        <ProtectedRoute user_type={"Student"} title="Learning Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <AddTestPage />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/practice-summary",
      element: (
        <ProtectedRoute user_type={"Student"}>
          <PracticeSummaryQuestion />
        </ProtectedRoute>
      ),
    },
    {
      path: "/question-pages",

      element: <QuestionPages />,
    },
    {
      path: "/preview/QuestionPages",
      element: <QuestionPages />,
    },
    {
      path: "/home/learning-management/:id",
      element: (
        <ProtectedRoute user_type={"Student"} title="Learning Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <LearningManagement />
          </Suspense>
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/learning-management/exam/:id",
      element: (
        <ProtectedRoute user_type={"Student"} title="Learning Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <LearningManagement />
          </Suspense>
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/learning-management/research-test/:examName/:id",
      element: (
        <ProtectedRoute user_type={"Student"} title="Learning Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <LearningManagement />
          </Suspense>
        </ProtectedRoute>
      ),
    },
  ],
};

export default MainRoutes;
