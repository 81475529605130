import { useAppSelector } from "Redux/App/hooks";
import React from "react";
import { useState, useEffect } from "react";

export const useTimer = (initialTime: string) => {
  const [time, setTime] = useState(initialTime);

  const isAllQuestionAttempt = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );
  useEffect(() => {
    let intervalId: any;

    if (time !== "00:00" && !isAllQuestionAttempt) {
      intervalId = setInterval(() => {
        const [minutes, seconds] = time?.split(":")?.map(Number);

        if (seconds > 0) {
          setTime(
            `${minutes.toString().padStart(2, "0")}:${(seconds - 1)
              .toString()
              .padStart(2, "0")}`
          );
        } else if (minutes > 0) {
          setTime(`${(minutes - 1).toString().padStart(2, "0")}:59`);
        } else {
          setTime("00:00");
        }
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [time, isAllQuestionAttempt]);

  return time;
};
