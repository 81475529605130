import { Logout } from "@mui/icons-material";
import { Box, Button, Divider, List, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  FetchAtomicAndContainerData,
  setActiveIndex,
  setIconSidebar,
  setSideBar,
} from "Redux/feature/StudentHome";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import kidumLogo from "assets/images/kidum-logo.jpg";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import StepIndicator from "views/Questions/StepIndicator";
import { ReactComponent as Brain } from "../../../assets/images/brain.svg";
import { useSearchParams } from "react-router-dom";
import { setPreviewName, setPreviousElement } from "Redux/feature/Questions";
import { updateSidebarData } from "Redux/feature/onlinePracticeExamSlice";
import AtomicIcon from "../../../assets/images/atomicIconRed.svg";
import { setCurrentTab } from "Redux/feature/StudentmanagementExamResultSlice";
import { getImageUrl } from "hooks/imageUrl";
import { getCookie } from "views/common/cookieUtils";
const Sidebar = ({ currentStep = 0 }) => {
  const location = useLocation();
  const [searchParams, setSerchParams] = useSearchParams();
  const iconSidebar: any = useAppSelector(
    (state) => state.StudentsHomeData.iconSidebar
  );

  const previewName = useAppSelector((state) => state.Questions.previewName);
  let atomicUnitData: any = useAppSelector(
    (state) => state.StudentsHomeData.atomicUnitsData
  );
  const containerId = searchParams.get("container_type_id");
  const is_student_exam = searchParams.get("is_student_exam");
  const atomicType = searchParams.get("atomic_type");
  const atomic_id = searchParams.get("atomic_id");
  const defaultCourseId = getCookie("default_course_id");
  const isResearch = searchParams.get("isResearch");
  const assignmentDetail = JSON.parse(localStorage.getItem("assignment") || "[]")

  const activeAtomic = useAppSelector(
    (state) => state.StudentsHomeData.activeAtomicIndex
  );
  const selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  const container_type_idSeven =
    searchParams.get("container_type_id") === "7" ||
    searchParams.get("container_type_id") === "8";
  const sidebar: any = useAppSelector((state) =>
    !container_type_idSeven
      ? state.StudentsHomeData?.sidebar
      : location.pathname == `/practice-summary`
      ? state.OnlinePracticeExamSlice?.sidBarData
      : state.OnlinePracticeExamSlice?.sidBarData
  );

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const assignment_id = searchParams.get("assignment_id");
  const user_id = getCookie("id");
  useEffect(() => {
    const meetingIndex = atomicUnitData?.findIndex((item: any) => {
      return item.meeting.assignment.some(
        (assignmentData: any) => assignmentData.id == assignment_id
      );
    });
    const selectedObject = atomicUnitData[
      meetingIndex
    ]?.meeting?.assignment.find((item: any) => item.id == assignment_id);
    if (searchParams.get("atomic_id")) {
      const currentIndex = selectedObject?.atomics_containers?.findIndex(
        (item: any) => {
          return (
            item.types === "atomic" &&
            item.atomic[0]?.types.name === atomicType &&
            item.atomic_container_id == atomic_id
          );
        }
      );
      const nextElementData =
        selectedObject?.atomics_containers[currentIndex + 1];
      if (nextElementData?.is_completed) {
        dispatch(setPreviousElement(true));
      } else {
        dispatch(setPreviousElement(false));
      }
    } else {
      const currentIndex = selectedObject?.atomics_containers?.findIndex(
        (item: any) => {
          return (
            item.types === "container" &&
            item.atomic_container_id == searchParams.get("container_id")
          );
        }
      );
      const nextElementData =
        selectedObject?.atomics_containers[currentIndex + 1];
      if (nextElementData?.is_completed) {
        dispatch(setPreviousElement(true));
      } else {
        dispatch(setPreviousElement(false));
      }
    }
  }, [searchParams.get("container_id"), searchParams.get("atomic_id")]);

  useEffect(() => {
    if (!container_type_idSeven && user_id) {
      dispatch(
        FetchAtomicAndContainerData({
          id: user_id,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        })
      ).then((res: any) => {
        atomicUnitData = res?.payload?.data;
        handleAssignmentData();
      });
    }
  }, [assignment_id]);

  const handleAssignmentData = () => {
    let arr: any = [];
    let prevContainerTypeId: number | null = null;
    if(searchParams.get("product_exam")){
      if (assignmentDetail?.length > 0) {
        console.log(assignmentDetail,"assignmentdata")
        assignmentDetail?.forEach((data: any) => {
          
            if (
              data.types === "container" &&
              data.container[0]?.container_type_id == 5
            ) {
              if (prevContainerTypeId != 5) {
                arr.push(data);
                prevContainerTypeId = 5;
              }
            } else {
              arr.push(data);
              prevContainerTypeId = null;
            }
        });
      }
    }else{
      atomicUnitData?.forEach((atomicData: any) => {
        if (atomicData?.meeting?.assignment?.length > 0) {
          atomicData.meeting?.assignment?.forEach((item: any) => {
            if (item.atomics_containers?.length > 0) {
              item.atomics_containers?.forEach((data: any) => {
                if (data?.assignment_id == assignment_id) {
                  const matchingObject = atomicData?.meeting?.assignment.find(
                    (test: any) => test.id == assignment_id
                  );
                  const assignment_name = matchingObject.preview_name;
                  if (matchingObject?.progress == 100) {
                    dispatch(setIconSidebar(matchingObject?.icon?.icon1));
                  } else {
                    dispatch(setIconSidebar(matchingObject?.icon?.icon2));
                  }
                  dispatch(setPreviewName(assignment_name));
                  if (
                    data.types === "container" &&
                    data.container[0]?.container_type_id == 5
                  ) {
                    if (prevContainerTypeId != 5) {
                      arr.push(data);
                      prevContainerTypeId = 5;
                    }
                  } else {
                    arr.push(data);
                    prevContainerTypeId = null;
                  }
                }
              });
            }
          });
        }
      });
    }

    

    dispatch(setSideBar(arr));

    if((containerId=="10"||containerId=="11")&&isResearch){
      var jsonString = JSON.stringify(arr);
      localStorage.setItem("assignment", jsonString);
      const lastIndex = arr?.length - 1;
      dispatch(setActiveIndex(lastIndex));

    }
  };

  const handleSubmit = () => {
    if (searchParams.get("isResearch")) {
      // navigate("/home/learning-management/");

      navigate(-1);
    }
    else if
    (searchParams.get("product_exam")){
      navigate("/home/learning-management/exam-analysis/add-test");
    }else if(is_student_exam){
      navigate("/home/learning-management/");

    } else if (searchParams.get("container_type_id") == "7") {
      navigate(`/student/library`, {
        state: { fromQuestionPageOfNewPractice: true },
      });
      dispatch(updateSidebarData([]));
    } else if (searchParams.get("container_type_id") == "8") {
      navigate(`/student/library`, {
        state: { fromQuestionPageOfTheOven: true },
      });
      dispatch(updateSidebarData([]));
    } else if (searchParams.get("isFromTable")) {
      navigate("/home/learning-management");
      dispatch(setCurrentTab(1));
    } else {
      navigate("/student-home");
      dispatch(setSideBar([]));
    }
  };
  return (
    <Box
      sx={{
        height: "100%",
        width: ["-webkit-fill-available", "-moz-available"],
        overflow: "hidden",
      }}
    >
      <Stack
        alignItems="flex-end"
        justifyContent="space-between"
        textAlign="left"
        borderLeft={1}
        borderColor="divider"
        height="100%"
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            width: containerId == "7" ? { md: "206px", xs: "100%" } : "100%",
            height: { xs: "calc(450px - 99px)", md: "100vh" },
          }}
        >
          {location.pathname === "/home/student/question" ? (
            <>
              <></>
            </>
          ) : (
            <>
              <>
                <Box
                  display={{ xs: "none", md: "flex" }}
                  sx={{
                    width: "100%",
                    height: { xs: "55px", md: "70px" },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={kidumLogo} alt="kidum" style={{ width: "90px" }} />
                </Box>
              </>
            </>
          )}

          <Divider variant="fullWidth" />
          <Stack
            display={{ xs: "none", md: "flex" }}
            height={{ xs: "50px", md: "75px" }}
            direction="row"
            alignItems="center"
            marginRight={2}
          >
            {!container_type_idSeven ? (
              <>
                {/* <BookIcon /> */}
                {/* <Box>
                    {" "}
                    <img
                      src={imagePath + iconSidebar}
                      alt="icon"
                      style={{
                        height: "20px",
                        maxWidth: "20px",
                      }}
                    />
                  </Box> */}
                {iconSidebar === null || typeof iconSidebar === "undefined" ? (
                  <>
                    <img
                      src={AtomicIcon}
                      alt="icon"
                      style={{
                        height: "20px",
                        maxWidth: "20px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={getImageUrl(iconSidebar, "")}
                      alt="icon"
                      style={{
                        height: "20px",
                        maxWidth: "20px",
                      }}
                    />{" "}
                  </>
                )}

                {previewName ? (
                  <Typography
                    variant="body2"
                    marginRight={1}
                    marginLeft={1}
                    sx={{
                      maxWidth: "120px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {previewName}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    marginRight={1}
                    marginLeft={1}
                    sx={{
                      maxWidth: "120px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <FormattedMessage id="task-name" />
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Brain />
                <Typography variant="body2" marginRight={1} marginLeft={1}>
                  <FormattedMessage id="online-practice" />
                </Typography>
              </>
            )}
          </Stack>
          {/* <Divider variant="fullWidth" /> */}
          <Box
            sx={{
              bgcolor: "background.paper",
              height: "60%",
              overflowy: "auto",
              overflowX: "hidden",
            }}
          >
            {sidebar?.map((item: any, index: number) => {
              if (!container_type_idSeven) {
                return (
                  <div key={item.id}>
                    {item?.types === "atomic" ? (
                      <div>
                        {item.atomic.map((type: any) => (
                          <List key={type.id} sx={{ padding: "0" }}>
                            <StepIndicator
                              isActive={index == activeAtomic}
                              isChecked={item.is_completed}
                              primaryText={type.title}
                            />
                          </List>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {item.container.map((type: any) => (
                          <List key={type.id} sx={{ padding: "0" }}>
                            <StepIndicator
                              isActive={index == activeAtomic}
                              isChecked={item.is_completed}
                              container_type_id={type.container_type_id}
                              primaryText={
                                type.container_type_id == 5
                                  ? "טופס בחינה"
                                  : type.display_name
                              }
                            />
                          </List>
                        ))}
                      </div>
                    )}
                  </div>
                );
              } else if (location.pathname == `/practice-summary`) {
                return (
                  <>
                    <Stack
                      height={{ xs: "40px", md: "40px" }}
                      direction="row"
                      alignItems="center"
                      marginRight={1}
                      marginLeft={1}
                    >
                      <Typography variant="body2" marginRight={1}>
                        {item}
                      </Typography>
                    </Stack>
                    <Divider variant="fullWidth" />
                  </>
                );
              } else {
                return (
                  <>
                    <Stack
                      height={{ xs: "40px", md: "40px" }}
                      direction="row"
                      alignItems="center"
                      marginRight={1}
                      marginLeft={1}
                    >
                      <Typography variant="body2" marginRight={1}>
                        {item}
                      </Typography>
                    </Stack>
                    <Divider variant="fullWidth" />
                  </>
                );
              }
            })}

            {/* {AtomicData.map((item: any, index: number) => {
                return (
                  <div key={item.id}>
                    {item.atomic.map((type: any) => {
                      return (
                        <List sx={{ padding: "0" }}>
                          <StepIndicator
                            isActive={index === activeAtomic}
                            isChecked={item.is_completed}
                            primaryText={type.title}
                          />
                        </List>
                      );
                    })}
                  </div>
                );
              })}
              {containerData.map((item: any) => {
                return (
                  <div key={item.id}>
                    {item.container.map((type: any, index: any) => {
                      return (
                        <List sx={{ padding: "0" }}>
                          <StepIndicator
                            isActive={false}
                            isChecked={item.is_completed}
                            primaryText={type.title}
                          />
                        </List>
                      );
                    })}
                  </div>
                );
              })} */}
            {/* 
               <List>
                    
                        <StepIndicator isActive={currentStep === 1} isChecked={currentStep > 1} primaryText={<FormattedMessage id="guidelines" />} />
                        <StepIndicator isActive={currentStep === 2} isChecked={currentStep > 2} primaryText={<FormattedMessage id="digital-book" />} />
                        <StepIndicator isActive={currentStep === 3} isChecked={currentStep > 3} primaryText={<FormattedMessage id="assignment" />} />
                        <StepIndicator isActive={currentStep === 4} isChecked={currentStep > 4} primaryText={<FormattedMessage id="practice" />} />
                    </List>  */}
          </Box>
        </Box>
      </Stack>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "70px",
          width: containerId === "10"||containerId === "11" ? "auto" : "100%",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "18px",
          }}
          fullWidth
          startIcon={
            <Logout
              sx={{
                ml: 2,
              }}
            />
          }
          onClick={handleSubmit}
        >
          <FormattedMessage id="exit" />
        </Button>
      </Box>
    </Box>
  );
};

export default Sidebar;
