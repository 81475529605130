import { useLocation, matchRoutes } from "react-router-dom";

const routes = [
  { path: "/student-home" },
  { path: "/home/classroom-management" },
  { path: "/student/library" },
  { path: "/home/library/product-page" },
  { path: "/home/daybook" },
  { path: "/home/tools/roboclock" },
  { path: "/home/tools/vocabulary" },
  { path: "/teacher-home" },
  { path: "/home/student-management" },
  { path: "/teacher/library" },
  { path: "/home/student-management/presence" },
  { path: "/home/student-management/Student-exam" },
  { path: "/home/student-management/lesson-learn" },
  { path: "/home/learning-management" },
  { path: "/home/student-management/work-groups" },
  { path: "/home/student-management/meeting" },
  { path: "/home/student-management/assignments" },
  { path: "/home/student-management/tests" },
  { path: "/home/student-management/class-map" },
];

export const useCurrentPath = () => {
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location) || [
    { route: { path: "" } },
  ];

  return route.path;
};
