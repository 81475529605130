import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Box,
  Typography,
  Alert,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import { FetchSendOtp } from "Redux/feature/UserAuth";

const Verification = () => {
  const location = useLocation();
  const workerId = useAppSelector((state) => state.UserAuth.workerId);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentPath = location.pathname;
  const [showAlert, setShowAlert] = useState(false);

  const responseMessage: any = useAppSelector(
    (state) => state.UserAuth.message
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    dispatch(FetchSendOtp({ username: workerId })).then((e) => {
      e.payload.status
        ? navigate("/verifyotp", { state: currentPath })
        : setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    });
  };

  useEffect(() => {
    if (workerId === "") {
      navigate("/");
    }
  }, [workerId]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        width={{ xs: "100%", sm: "394px" }}
        sx={{
          mx: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: "15px",
          pb: "35px",
        }}
      >
        <Box width="100%">
          <Typography
            sx={{ fontSize: "32px", fontWeight: 700 }}
            className="login-title"
          >
            <FormattedMessage id="forgot-password" />?
          </Typography>
        </Box>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 1, width: "100%" }}
        >
          <Typography
            variant="subtitle1"
            textAlign={{ xs: "center", md: "right" }}
          >
            <FormattedMessage id="sample-text" />
          </Typography>
          <InputLabel
            htmlFor="code"
            sx={{ marginTop: "40px", marginBottom: "5px" }}
          >
            <FormattedMessage id="userName" />
          </InputLabel>
          <TextField
            margin="none"
            required
            fullWidth
            value={workerId}
            id="code"
            name="code"
            type="text"
            size="small"
            sx={{ fontSize: "16px" }}
          />
          {showAlert && (
            <Alert
              className="removeIconLessonBooksAlert"
              sx={{
                width: "auto",
                height: "auto",
                color: "red",
                fontWeight: 600,
                fontSize: "16px",
                padding: "0px",

                backgroundColor: "transparent",
              }}
            >
              {responseMessage}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: "40px", mb: 2, height: "40px", fontSize: "18px" }}
          >
            <FormattedMessage id="send-verification-code" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Verification;
