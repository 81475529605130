import Routes from "./routes/index";
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";
import Locales from "./utils/locales/Locales";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}
declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
  }
}

function App() {
  const purpleTheme = extendTheme({
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: "#7A49B9",
          },
          warning: {
            main: "#D9B216",
          },
          neutral: {
            main: "#878E95",
            contrastText: "#fff",
          },
        },
      },
      dark: {
        palette: {
          primary: {
            main: "#7A49B9",
          },
          warning: {
            main: "#D9B216",
          },
        },
      },
    },
  });

  return (
    <CssVarsProvider theme={purpleTheme}>
      <Locales>
        <Routes />
      </Locales>
    </CssVarsProvider>
  );
}

export default App;
