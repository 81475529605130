import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getCookie, setCookie } from "views/common/cookieUtils";

const ProtectedRoute = ({
  children,
  user_type,
  title = "Learning Management System",
}: any) => {
  const accessToken = getCookie("accessToken");
  const user_id = getCookie("id");
  const userType = getCookie("user_type");

  useEffect(() => {
    document.title = `Kidum | ${title}`;
    window.scrollTo(0, 0);

    // Function to update the access token expiry
    const updateAccessToken = () => {
      const currentToken = getCookie("accessToken");
      if (currentToken) {
        setCookie("accessToken", getCookie("accessToken"), 4, "h");
        setCookie("id", getCookie("id"), 4, "h");
        setCookie("user_type", getCookie("user_type"), 4, "h");
        setCookie("user_name", getCookie("user_name"), 4, "h");
        setCookie("default_course_id", getCookie("default_course_id"), 4, "h");
      }
    };

    const eventHandler = (event: Event) => {
      updateAccessToken();
    };

    const events = ["click", "keydown", "scroll"];

    // Add event listeners
    events.forEach((event) => window.addEventListener(event, eventHandler));

    // Clean up event listeners on unmount
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, eventHandler)
      );
    };
  }, [title]);

  if (!accessToken || !user_id || !user_type.includes(userType)) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
