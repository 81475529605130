import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";

interface Data {
  teacher_id:string | null;
  course_id:string;
}

interface SubmitData {
  teacher_id:string | null;
  course_id:string;
  meeting_id:number | undefined;
  attendanceData:any[]
}

export const FetchStudentsAndMeetings = createAsyncThunk(
  "AttendanceSlice/getStudentsAndMeetings",
  async (data: Data) => {
    try {
      const res = await axiosClient.get(
        `get-students-and-meetings?teacher_id=${data.teacher_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const SubmitStudentsAttendance = createAsyncThunk(
  "AttendanceSlice/fillStudentsAttendance",
  async (data: SubmitData) => {
    try {
      const res = await axiosClient.post("fill-students-attendance", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

interface initialStage {
  attendanceData: any[];
  isLoading: boolean;
  error: any;
  submitAttendance: any[];
}

const initialState: initialStage = {
  attendanceData: [],
  isLoading: false,
  error: null,
  submitAttendance: [],
};

const AttendanceSlice = createSlice({
  name: "AttendanceSlice",
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(FetchStudentsAndMeetings.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchStudentsAndMeetings.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          attendanceData: action.payload.data,
        };
      })
      .addCase(FetchStudentsAndMeetings.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(SubmitStudentsAttendance.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(SubmitStudentsAttendance.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          submitAttendance: action.payload.data,
        };
      })
      .addCase(SubmitStudentsAttendance.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      });
  },
});

export default AttendanceSlice.reducer;
