import { Outlet, Navigate } from "react-router-dom";
import ImageTemplate from "../views/common/ImageTemplate";
import LandingScreenImg from "../assets/images/landing-screen.png";
import VerifyUserId from "../views/auth/VerifyUserId";
import Login from "../views/auth/Login";
import VerifyOTP from "../views/auth/VerifyOTP";
import CreatePassword from "../views/auth/CreatePassword";
import ForgotPassword from "../views/auth/ForgotPassword";
import { getCookie } from "views/common/cookieUtils";

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const user_id = getCookie("id");
  const userType = getCookie("user_type");
  
  // If usertype and userId exist, redirect to the student home page
  if (user_id && userType) {
    if(userType=="Student"){
      return <Navigate to="/student-home" />;

    }
    else{
      return <Navigate to="/teacher-home" />;

    }
  }
  
  return <>{children}</>;
};
const LoginRoutes = {
  path: "/",
  element: (
    <PrivateRoute>
      <Outlet />
    </PrivateRoute>
  ),
  children: [
    {
      path: "/",
      element: <ImageTemplate component={<Login />} image={LandingScreenImg} />,
    },
    {
      path: "/verifyotp",
      element: (
        <ImageTemplate component={<VerifyOTP />} image={LandingScreenImg} />
      ),
    },
    {
      path: "/first-login",
      element: (
        <ImageTemplate component={<VerifyUserId />} image={LandingScreenImg} />
      ),
    },
    {
      path: "/create-password",
      element: (
        <ImageTemplate
          component={<CreatePassword />}
          image={LandingScreenImg}
        />
      ),
    },
    {
      path: "/forgot-password",
      element: (
        <ImageTemplate
          component={<ForgotPassword />}
          image={LandingScreenImg}
        />
      ),
    },
  ],
};

export default LoginRoutes;
