import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Button, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  SubmitQuestionsAns,
  FetchQuestions,
  GetAllSubmittedQuestion,
  updateActiveQuestion,
  setProgressBarFlag,
  setQuizQuestionComplete,
  setNumericBar,
  setQuizColorChange,
  setStartIndex,
  setQuestionBar,
  setFetchAllSubmittedQuestions,
  setAction,
  setResetBook,
  setShowResult,
  setShowResultMode,
  setResetAss,
  setPreviewName,
  FetchOneQuestion,
} from "Redux/feature/Questions";
import { useNavigate, useSearchParams } from "react-router-dom";
import RenderOptions from "../ContainerType/RenderOptions";
import DescriptionBox from "../DescriptionBox";
import QuestionSideBar from "../Sidebar/QuestionSideBar";
import QuestionAttemptAndCheck from "../QuestionTypes/QuestionAttemptAndCheck";
import { ReactComponent as ResetBtn } from "../../../assets/images/reset.svg";
import {
  CompleteContainerAtomic,
  FetchAtomicAndContainerData,
  GetNextAssignment,
  setAssignmentData,
  setIconSidebar,
  setOpenAssignmentSuccess,
  setOpenSessionSuccess,
  setSideBar,
  setActiveIndex,
} from "Redux/feature/StudentHome";
import { ResetQuestionsState } from "Redux/feature/Questions";
import AssignmentDialog from "views/common/AssignmentDialog";
import SessionDialog from "views/common/SessionDialog";
import { getCookie } from "views/common/cookieUtils";
interface Question {
  id: any;
  question_id: number;
  answer: string | null;
  is_skipped_question: number;
}

const BookAssignment = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDialogOpenForCount, setIsDialogOpenForCount] =
    useState<boolean>(false);

  const [option_id, setOptionId] = useState<any>();
  const [searchParams, setSerchParams] = useSearchParams();
  const user_id = getCookie("id");

  const atomicSideBar: any = searchParams.get("atomicSideBar");
  const assignment_id = searchParams.get("assignment_id");
  const navigate = useNavigate();
  const containerID = searchParams.get("container_id");
  const {
    questions: appQuestions,
    isLoading,
    renderPreview,
  }: any = useAppSelector((state) => state.Questions);
  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );
  const showResult = useAppSelector((state) => state.Questions.showResult);
  const showResultMode = useAppSelector(
    (state) => state.Questions.showResultMode
  );
  const action = useAppSelector((state) => state.Questions.action);
  const assignment: any = useAppSelector(
    (state) => state.StudentsHomeData.assignment
  );
  const previousElement = useAppSelector(
    (state) => state.Questions.previousElement
  );
  const resetBook: any = useAppSelector((state) => state.Questions.resetBook);
  const selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  let atomicUnitData: any = useAppSelector(
    (state) => state.StudentsHomeData.atomicUnitsData
  );
  const atomicIndex = useAppSelector(
    (state) => state.StudentsHomeData.activeAtomicIndex
  );

  const fetchAllSubmittedQuestionAnswer: any = useAppSelector(
    (state) => state.Questions.fetchAllSubmittedQuestions
  );
  const resetAss = useAppSelector((state) => state.Questions.resetAss);
  const assignmentModal: boolean = useAppSelector(
    (state) => state.StudentsHomeData.openAssignmentSuccess
  );
  const sessionModal: boolean = useAppSelector(
    (state) => state.StudentsHomeData.openSessionSuccess
  );
  const isSmallScreen = useMediaQuery("(min-width: 900px)");
  const defaultCourseId = getCookie("default_course_id");

  useEffect(() => {
    const questionIds = appQuestions.map((question: Question) => question.id);
    const initialQuestionsState: Question[] = questionIds.map(
      (questionId: any) => ({
        question_id: questionId,
        answer: null,
        is_skipped_question: 1,
      })
    );
    setQuestions(initialQuestionsState);
  }, [appQuestions]);
  useEffect(() => {
    if (resetAss) {
      dispatch(setNumericBar(true));
    } else {
      dispatch(setNumericBar(false));
    }
  }, [user_id]);

  const handleOptionClick = (questionId: number, option: string) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.question_id === questionId
          ? { ...question, answer: option, is_skipped_question: 0 }
          : question
      )
    );
  };
  useEffect(() => {
    if (resetBook) {
      handleResetFormClick();
      dispatch(setResetBook(false));
    }
  }, [resetBook]);

  const handleResetFormClick = () => {
    const resetQuestions: Question[] = questions.map((question) => ({
      ...question,
      answer: null,
      is_skipped_question: 1,
    }));
    setQuestions(resetQuestions);
  };

  const handleClosePopup = () => {
    setIsDialogOpen(false);
    dispatch(setProgressBarFlag(false));
  };

  const handleDialogCloseForCount = () => {
    setIsDialogOpenForCount(false);
  };

  const nextPage = () => {
    dispatch(
      SubmitQuestionsAns({
        user_id: user_id,
        container_id: containerID,
        data: questions,
        course_id: defaultCourseId ? defaultCourseId : selectedCourse,
      })
    ).then(() => {
      dispatch(setAction(true));
      dispatch(setShowResult(true));
      dispatch(setShowResultMode(true));
      setIsDialogOpen(false);
      callGetAllSubmittedQuestion();
      dispatch(setNumericBar(true));
    });
  };
  const currentQuestion = activeQuestion + 1;
  const handleSubmitAnswers = () => {
    const hasNonNullAnswer = questions.some(
      (question) => question.answer !== null
    );

    if (hasNonNullAnswer) {
      dispatch(
        SubmitQuestionsAns({
          user_id: user_id,
          container_id: containerID,
          data: questions,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        })
      ).then(() => {
        dispatch(setAction(true));
        dispatch(setShowResult(true));
        dispatch(setShowResultMode(true));
        dispatch(setNumericBar(true));
        callGetAllSubmittedQuestion();
      });
    } else {
      setIsDialogOpen(true);
    }
    dispatch(setQuizQuestionComplete(true));
  };
  const callGetAllSubmittedQuestion = () => {
    dispatch(
      GetAllSubmittedQuestion({
        user_id: user_id,
        container_id: containerID,
      })
    );
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (renderPreview) {
      dispatch(setAction(true));
      dispatch(setShowResult(true));
      dispatch(setShowResultMode(true));
      dispatch(setNumericBar(true));
      dispatch(setQuizQuestionComplete(true));
    }
  }, [renderPreview]);

  useEffect(() => {
    const question_id = searchParams.get("question_id");
    const collection_id = searchParams.get("collection_id");
    if (question_id || collection_id) {
      dispatch(
        FetchOneQuestion({
          question_id,
          collection_id,
          container_id: searchParams.get("container_id"),
        })
      );
    } else {
      dispatch(
        FetchQuestions({
          user_id: user_id ? user_id : null,
          container_id: containerID,
        })
      );
    }
  }, [containerID]);
  useEffect(() => {
    const currentIndex = assignment.findIndex((item: any) => {
      return (
        item.types === "container" &&
        item.atomic_container_id == searchParams.get("container_id")
      );
    });
    dispatch(setActiveIndex(currentIndex));
  }, [searchParams.get("container_id")]);
  const handleSelectOption = (e: any) => {
    setOptionId(e);
  };

  useEffect(() => {
    if (!isSmallScreen) {
      if (activeQuestion >= 5 && activeQuestion < 10) {
        dispatch(setStartIndex(5));
      } else if (activeQuestion >= 10 && activeQuestion < 15) {
        dispatch(setStartIndex(10));
      } else if (activeQuestion >= 15 && activeQuestion < 20) {
        dispatch(setStartIndex(15));
      } else if (activeQuestion >= 20 && activeQuestion < 25) {
        dispatch(setStartIndex(20));
      } else if (activeQuestion >= 25 && activeQuestion < 30) {
        dispatch(setStartIndex(25));
      } else if (activeQuestion >= 30 && activeQuestion < 35) {
        dispatch(setStartIndex(30));
      } else if (activeQuestion >= 35 && activeQuestion <= 40) {
        dispatch(setStartIndex(35));
      }
    } else {
      if (activeQuestion >= 10 && activeQuestion < 20) {
        dispatch(setStartIndex(10));
      } else if (activeQuestion >= 20 && activeQuestion < 30) {
        dispatch(setStartIndex(20));
      } else if (activeQuestion >= 29 && activeQuestion <= 40) {
        dispatch(setStartIndex(29));
      }
    }
  }, [activeQuestion, isSmallScreen]);
  const handleNextCheckAnswer = () => {
    dispatch(
      GetAllSubmittedQuestion({
        user_id: user_id,
        container_id: containerID,
      })
    );
    if (showResultMode === false) {
      dispatch(setShowResultMode(true));
      dispatch(updateActiveQuestion(0));
    }
    if (activeQuestion === appQuestions.length - 1) {
      dispatch(setQuizQuestionComplete(true));
      dispatch(setQuizColorChange(true));
    } else {
      dispatch(updateActiveQuestion(activeQuestion + 1));
    }
    dispatch(setProgressBarFlag(true));
  };

  const handleAssignmentData = () => {
    let arr: any = [];
    let prevContainerTypeId: number | null = null;

    atomicUnitData?.forEach((atomicData: any) => {
      if (atomicData?.meeting?.assignment?.length > 0) {
        atomicData.meeting?.assignment?.forEach((item: any) => {
          if (item.atomics_containers?.length > 0) {
            item.atomics_containers?.forEach((data: any) => {
              if (data?.assignment_id == assignment_id) {
                const matchingObject = atomicData?.meeting?.assignment.find(
                  (test: any) => test.id == assignment_id
                );
                const assignment_name = matchingObject.preview_name;
                if (matchingObject?.progress == 100) {
                  dispatch(setIconSidebar(matchingObject?.icon?.icon1));
                } else {
                  dispatch(setIconSidebar(matchingObject?.icon?.icon2));
                }
                dispatch(setPreviewName(assignment_name));
                if (
                  data.types === "container" &&
                  data.container[0].container_type_id == 5
                ) {
                  if (prevContainerTypeId != 5) {
                    arr.push(data);
                    prevContainerTypeId = 5;
                  }
                } else {
                  arr.push(data);
                  prevContainerTypeId = null;
                }
              }
            });
          }
        });
      }
    });

    dispatch(setSideBar(arr));
  };
  const nextAssignment = () => {
    const meetingIndex = atomicUnitData.findIndex((item: any) => {
      return item.meeting.assignment.some(
        (assignmentData: any) => assignmentData.id == assignment_id
      );
    });
    if (user_id) {
      dispatch(
        GetNextAssignment({
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,

          meeting_id: localStorage.getItem("meetingId")
            ? localStorage.getItem("meetingId")
            : atomicUnitData[meetingIndex]?.meeting?.id,
          assignment_id: assignment_id,
          student_id: user_id,
        })
      ).then((resp: any) => {
        if (resp.payload.data.length == 0) {
          if (resp.payload.meeting_completed == 1) {
            dispatch(setOpenSessionSuccess(true));
          }
          navigate("/student-home");
        } else {
          if (resp.payload.meeting_completed == 1) {
            dispatch(setOpenSessionSuccess(true));
          } else {
            dispatch(setOpenAssignmentSuccess(true));
          }
          var jsonString = JSON.stringify(resp.payload.data.atomics_containers);
          localStorage.setItem("assignment", jsonString);
          const meetingData = JSON.stringify(
            resp.payload.data.meeting_assignment.meeting_id
          );
          localStorage.setItem("meetingId", meetingData);

          dispatch(setAssignmentData(resp.payload.data.atomics_containers));

          const indexFirstNotCompleted =
            resp.payload.data.atomics_containers?.findIndex(
              (item: { is_completed: 0 }) => item.is_completed == 0
            );
          dispatch(setActiveIndex(indexFirstNotCompleted));
          const firstNotCompletedItem =
            resp.payload.data.atomics_containers[indexFirstNotCompleted];
          if (firstNotCompletedItem?.types == "container") {
            const examTime =
              firstNotCompletedItem?.container[0]?.container_duration;

              let containerUrl
              if(firstNotCompletedItem?.container[0]?.container_type_id == 10 ||
                firstNotCompletedItem?.container[0]?.container_type_id == 11){
                  containerUrl = `/question-pages?container_type_id=${firstNotCompletedItem?.container[0]?.container_type_id}&container_id=${firstNotCompletedItem?.atomic_container_id}&assignment_id=${firstNotCompletedItem?.assignment_id}&&chapter_type=${firstNotCompletedItem?.container[0]?.chapter_type}`;

                }else{
                   containerUrl = `/question-pages?container_type_id=${firstNotCompletedItem?.container[0]?.container_type_id}&container_id=${firstNotCompletedItem?.atomic_container_id}&assignment_id=${firstNotCompletedItem?.assignment_id}`;

                }            const state = { examTime };
            navigate(containerUrl, { state });
          } else {
            const atomicType = firstNotCompletedItem?.atomic[0]?.types.name;
            const assignmentId = firstNotCompletedItem?.assignment_id;
            const atomic_id = firstNotCompletedItem?.atomic_container_id;
            const link = firstNotCompletedItem?.atomic[0]?.link;

            const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;

            const state = { link };
            navigate(url, { state });
          }
        }
      });
    }
  };

  const handleComplete = () => {
    assignment.map((item: any, index: any) => {
      return null;
    });
    const currentIndex = assignment.findIndex((item: any) => {
      return (
        item.types === "container" && item.atomic_container_id == containerID
      );
    });
    let currentElement = assignment[currentIndex];
    const meetingIndex = atomicUnitData?.findIndex((item: any) => {
      return item.meeting.assignment.some(
        (assignmentData: any) => assignmentData.id == assignment_id
      );
    });
    if (user_id) {
      dispatch(
        CompleteContainerAtomic({
          student_id: user_id,
          assignment_id: currentElement?.assignment_id,
          meeting_id: localStorage.getItem("meetingId")
            ? localStorage.getItem("meetingId")
            : atomicUnitData[meetingIndex]?.meeting?.id,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,

          type: "container",
          id: currentElement?.atomic_container_id,
        })
      ).then(() => {
        dispatch(
          FetchAtomicAndContainerData({
            id: user_id,
            course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          })
        ).then((resp: any) => {
          atomicUnitData = resp.payload.data;
          handleAssignmentData();
          dispatch(setActiveIndex(currentIndex + 1));
          if (assignment.length - 1 === atomicIndex) {
            dispatch(ResetQuestionsState());
            dispatch(setActiveIndex(0));
            dispatch(setSideBar([]));
            if (resetAss) {
              navigate("/student-home");
              dispatch(setResetAss(false));
            } else {
              nextAssignment();
            }
          } else {
            if (currentIndex >= 0 && currentIndex < assignment.length - 1) {
              const nextElement = assignment[currentIndex + 1];
              if (nextElement.types == "container") {
                dispatch(setQuizQuestionComplete(false));
                dispatch(setFetchAllSubmittedQuestions([]));
                dispatch(updateActiveQuestion(0));
                if (nextElement?.container[0]?.container_type_id == "1") {
                  dispatch(setQuestionBar(false));
                }
                if (nextElement?.container[0]?.container_type_id == "6") {
                  dispatch(setProgressBarFlag(false));
                }
                dispatch(setQuizQuestionComplete(false));
                if (resetAss || previousElement) {
                  if (
                    (resetAss &&
                      nextElement.container[0]?.container_type_id == 4) ||
                    (nextElement.container[0]?.container_type_id == 4 &&
                      previousElement)
                  ) {
                    if (nextElement.container[0]?.container_type_id == 4) {
                      dispatch(
                        GetAllSubmittedQuestion({
                          user_id: user_id,
                          container_id: nextElement?.atomic_container_id,
                        })
                      );
                    } else {
                      dispatch(
                        GetAllSubmittedQuestion({
                          user_id: user_id,
                          assignment_id: nextElement?.assignment_id,
                        })
                      );
                    }
                    navigate(
                      `/question-pages?container_type_id=${nextElement.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`
                    );
                    dispatch(setAction(true));
                    dispatch(setShowResult(true));
                    dispatch(setShowResultMode(true));
                    dispatch(setNumericBar(true));
                    dispatch(setQuizQuestionComplete(true));
                  } else {
                    navigate(
                      `/practice-summary?container_type_id=${nextElement.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`
                    );
                  }
                  dispatch(
                    FetchQuestions({
                      user_id: user_id ? user_id : null,
                      container_id: nextElement?.atomic_container_id,
                    })
                  );
                } else {
                  const examTime =
                    nextElement?.container[0]?.container_duration;

                  const containerUrl = `/question-pages?container_type_id=${nextElement.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`;

                  const state = { examTime };
                  navigate(containerUrl, { state });
                }
                dispatch(setActiveIndex(currentIndex + 1));
              } else if (nextElement.types == "atomic") {
                const atomicType = nextElement?.atomic[0]?.types.name;
                const assignmentId = nextElement?.assignment_id;
                const atomic_id = nextElement?.atomic_container_id;
                const link = nextElement?.atomic[0]?.link;
                const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;
                const state = { link };
                navigate(url, { state });

                dispatch(setActiveIndex(currentIndex + 1));
              }
            } else {
              navigate("/student-home");
            }
          }
        });
      });
    }
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F8F9FA",
          overflow: "hidden",
          width: "100%",
          height: action === false ? "calc(100vh - 100px)" : "auto",
        }}
      >
        {action == false && (
          <Box>
            <Grid container justifyContent="center" marginTop={"15px"}>
              <Grid item>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    fontFamily: "Noto Sans Hebrew",
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "33px",
                    letterSpacing: "0px",
                    textAlign: "center",
                    color: "#222529",
                    direction: "ltr",
                  }}
                >
                  <FormattedMessage id="bookAssignmentHeader" />
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid
                item
                maxWidth={"1200px"}
                flexDirection={{ xs: "row", md: "row" }}
                gap={{ xs: "5px", md: "0px" }}
                justifyContent={{ xs: "space-between", md: "space-between" }}
                alignItems={{ xs: "flex-start", md: "center" }}
                marginTop={{ xs: "25px", md: "0px" }}
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    fontFamily: "Noto Sans Hebrew",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0px",
                    color: "#222529",
                    direction: { xs: "rtl", md: "ltr" },
                    width: { xs: "50%", md: "63%" },
                    marginRight: { xs: "25px", md: "0px" },
                  }}
                >
                  <FormattedMessage id="bookAssignmentHeader2" />
                </Typography>
                <Typography
                  variant="h5"
                  gutterBottom
                  marginLeft={{ xs: "15px", md: "10px" }}
                  style={{
                    fontFamily: "Noto Sans Hebrew",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "18px",
                    letterSpacing: "0px",
                    color: "#7A49B9",
                    cursor: "pointer",
                    width: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onClick={handleResetFormClick}
                >
                  <Box marginLeft={"6px"}>
                    <ResetBtn />
                  </Box>
                  <FormattedMessage id="reset-form" />
                </Typography>
              </Grid>
            </Grid>

            <Container
              style={{
                height: "calc(100vh - 185px)",
                overflow: "auto",
                marginTop: "20px",
                padding: "0px",
              }}
            >
              <Box
                style={{
                  borderRadius: "20px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                {isLoading && (
                  <h2 style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                    Loading
                  </h2>
                )}
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{
                    fontSize: "20px",
                    fontWeight: 700,
                    lineHeight: "27.2px",
                    color: "#222529",
                    paddingRight: "25px",
                    paddingTop: "15px",
                  }}
                >
                  <FormattedMessage id="multiple-choice-questions" />
                </Typography>
                <AssignmentDialog openAssignmentSuccess={assignmentModal} />
                <SessionDialog openSessionSuccess={sessionModal} />
                <Grid container direction="row" style={{ direction: "ltr" }}>
                  {questions.map((questionNumber, index) => (
                    <Grid
                      key={questionNumber.question_id}
                      item
                      xs={1}
                      sm={0.5}
                      md={0.5}
                      lg={0.38}
                      margin="0px 0px 0px 20px"
                      padding="0"
                    >
                      <Typography
                        style={{
                          textAlign: "center",
                          fontFamily: "Noto Sans Hebrew",
                          fontSize: "20px",
                          fontWeight: 700,
                        }}
                        variant="subtitle1"
                        padding="5px"
                      >
                        {index + 1}
                      </Typography>
                      <RenderOptions
                        questionId={questionNumber.question_id}
                        questions={questions}
                        handleOptionClick={handleOptionClick}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Container>
            <Box
              justifyContent="center"
              alignItems="center"
              style={
                atomicSideBar === false || atomicSideBar === null
                  ? { display: "flex", backgroundColor: "#F8F9FA" }
                  : { display: "none" }
              }
              marginTop={{ xs: "0px", md: "50px" }}
              marginBottom={"40px"}
            >
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmitAnswers}
                sx={{
                  margin: "40px",
                  height: "40px",
                  fontSize: "18px",
                  position: "absolute",
                }}
              >
                <FormattedMessage id="for-Submission" />
              </Button>
            </Box>
          </Box>
        )}
        <div style={{ height: "100%" }}>
          {isDialogOpen && (
            <Dialog
              open={isDialogOpen}
              onClose={handleClosePopup}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth={"xs"}
              PaperProps={{
                sx: { borderRadius: "30px" },
              }}
            >
              <Box
                style={{
                  padding: "25px",
                  paddingBottom: "35px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Button style={{ justifyContent: "end" }}>
                  <Close onClick={handleClosePopup} />
                </Button>
                <DialogContent style={{ padding: 0 }}>
                  <DialogContentText id="alert-dialog-description">
                    <Typography
                      style={{
                        color: "#222529",
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: "24px",
                      }}
                    >
                      <FormattedMessage id="no-answer-popup-title" />
                    </Typography>
                    <Typography
                      style={{
                        color: "#222529",
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: "24px",
                      }}
                    >
                      <FormattedMessage id="wouldYouLikeToMoveForward" />
                    </Typography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "block",
                    paddingTop: "20px",
                    marginInline: "auto",
                  }}
                >
                  <Stack direction="row" justifyContent="center" width="100%">
                    <Button
                      onClick={handleClosePopup}
                      autoFocus
                      variant="contained"
                      style={{
                        border: "1px solid #7A49B9",
                        marginInline: "5px",
                      }}
                    >
                      <FormattedMessage id="no" />
                    </Button>
                    <Button
                      onClick={nextPage}
                      style={{
                        border: "1px solid #7A49B9",
                        marginInline: "5px",
                      }}
                    >
                      <FormattedMessage id="yes" />
                    </Button>
                  </Stack>
                </DialogActions>
              </Box>
            </Dialog>
          )}
          {isDialogOpenForCount && (
            <Dialog
              open={isDialogOpenForCount}
              onClose={handleDialogCloseForCount}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth={"xs"}
              PaperProps={{
                sx: {
                  borderRadius: "30px",
                  width: "335px",
                  background: "#F1F3F5",
                },
              }}
            >
              <Box
                style={{
                  padding: "25px",
                  paddingBottom: "25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    width: "100%",
                  }}
                >
                  <Button>
                    <Close onClick={handleDialogCloseForCount} />
                  </Button>
                  <Typography
                    marginBottom="10px"
                    style={{
                      color: "#000",
                      textAlign: "center",
                      fontFamily: "Noto Sans Hebrew",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    <FormattedMessage id="researchPractice" />
                  </Typography>
                </Box>

                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Box
                      sx={{
                        display: "flex",
                        padding: "12px 24px",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "3px",
                        borderRadius: "10px",
                        border: "1px solid var(--black-black-40, #DFE2E5)",
                        background: "#FFF",
                      }}
                    >
                      <Box>
                        <p
                          style={{
                            color: "var(--blue-blue-60, #3397DB)",
                            textAlign: "center",

                            fontFamily: "Noto Sans Hebrew",
                            fontSize: "24px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                          }}
                        >
                          {currentQuestion}

                          <span
                            style={{
                              color: "var(--blue-blue-60, #3397DB)",
                              textAlign: "center",
                              fontFamily: "Noto Sans Hebrew",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                              letterSpacing: "-1px",
                            }}
                          >
                            /{questions.length}
                          </span>
                        </p>
                      </Box>

                      <Typography
                        marginBottom="10px"
                        style={{
                          color: "#AEB5BC",
                          textAlign: "center",
                          fontFamily: "Noto Sans Hebrew",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }}
                      >
                        <FormattedMessage id="there-will-settlement" />
                      </Typography>
                    </Box>
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "block",
                    marginInline: "auto",
                  }}
                >
                  <Stack direction="row" justifyContent="center" width="100%">
                    <Button
                      onClick={nextPage}
                      style={{
                        padding: "9px 20px",
                        borderRadius: "8px",
                        background: "#7A49B9",
                        boxShadow:
                          "0px 2px 4px 0px rgba(52, 60, 68, 0.16), 0px 0px 1px 0px rgba(117, 131, 142, 0.04)",
                        color: " #FFF",
                        textAlign: "center",
                        fontFamily: "Noto Sans Hebrew",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "120%",
                        letterSpacing: "0.15px",
                      }}
                    >
                      לתחקור המלא
                    </Button>
                  </Stack>
                </DialogActions>
              </Box>
            </Dialog>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "auto",
            }}
          >
            <Box
              sx={{
                height: "calc(100vh - 70px)",
                backgroundColor: "#E8EBED",
                display: { xs: "none", md: "block" },
              }}
            >
              {action === true && !renderPreview ? (
                <>
                  <QuestionSideBar question_id={appQuestions[activeQuestion]} />
                </>
              ) : (
                <></>
              )}
            </Box>
            {/* </Box> */}

            <Box
              sx={{
                backgroundColor: "#F8F9FA",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                height={{
                  xs: "calc(100vh - 210px)",
                  md: "auto",
                }}
                padding={{
                  xs: "50px 25px 0px 15px",
                  md: "50px 141px 333px 141px",
                }}
              >
                <Box>
                  {action ? (
                    <>
                      {" "}
                      <div>
                        <Typography
                          className={`s `}
                          marginBottom="10px"
                          style={{
                            color: "#878E95",
                            fontWeight: 100,
                            textAlign: "right",
                          }}
                        >
                          <FormattedMessage
                            id="questionInfo"
                            values={{
                              totalQuestions: questions.length,
                              currentQuestion,
                            }}
                          />
                        </Typography>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
                <Box>
                  {action ? (
                    <Box borderTop="2px solid #E6E6E6">
                      <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                        {appQuestions.map(
                          (item: any, index: any) =>
                            activeQuestion === index && (
                              <Grid mt="15px">
                                <Box
                                  onClick={() => setIsDialogOpenForCount(true)}
                                  sx={{
                                    display: { xs: "flex", md: "none" },
                                    marginTop: "5px",
                                    marginBottom: "15px",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    gap: "5px",
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{ fontSize: "16px", fontWeight: 600 }}
                                    >
                                      <FormattedMessage id="researchPractice" />
                                    </Typography>
                                  </Box>
                                  <Box>
                                    {isDialogOpenForCount ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <g transform="rotate(180, 10, 10)">
                                          <path
                                            d="M5 7.5L10 12.5L15 7.5"
                                            stroke="#7A49B9"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </g>
                                      </svg>
                                    ) : (
                                      <>
                                        {" "}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M5 7.5L10 12.5L15 7.5"
                                            stroke="#7A49B9"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </>
                                    )}
                                  </Box>
                                </Box>

                                <QuestionAttemptAndCheck
                                  options={item.options}
                                  handleSelection={handleSelectOption}
                                  horizontal={1}
                                />
                                <Box
                                  marginTop="20px"
                                  style={{ backgroundColor: "#E8EBED" }}
                                >
                                  <DescriptionBox
                                    key={index}
                                    description={item.solving_explanation}
                                    file_path={
                                      item?.explanation_image?.file_path
                                    }
                                  />
                                </Box>
                              </Grid>
                            )
                        )}
                      </div>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              <Box sx={{ minHeight: "70px" }}>
                {(showResult || action) && !renderPreview ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#FFFFFF",
                        height: "70px",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        disabled={
                          activeQuestion ==
                          fetchAllSubmittedQuestionAnswer.length - 1
                        }
                        sx={{
                          backgroundColor: "7A49B9",
                          my: "15px",
                          width: "152px",
                          height: "40px",
                          padding: "9px 22px",
                          borderRadius: "8px",
                        }}
                        onClick={() => handleNextCheckAnswer()}
                      >
                        <FormattedMessage id="next-question" />
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #7A49B9",
                          color: "#7A49B9",
                          my: "15px",
                          marginRight: "10px",
                          height: "40px",
                          padding: "10px 30px",
                          borderRadius: "8px",
                        }}
                        onClick={handleComplete}
                      >
                        <FormattedMessage id="done-researching" />
                      </Button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default BookAssignment;
