import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  FetchQuestions,
  GetAllSubmittedQuestion,
  setProgressBarFlag,
  setQuizQuestionComplete,
  SubmitQuestionsAns,
  updateActiveQuestion,
} from "Redux/feature/Questions";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import QuestionSideBar from "../Sidebar/QuestionSideBar";
import { FormattedMessage } from "react-intl";
import { Button, Typography } from "@mui/material";
import DescriptionBox from "../DescriptionBox";
import VerbatimText from "../QuestionTypes/VerbatimText";
import EnglishLayout from "../QuestionTypes/EnglishLayout";
import ImageQuestion from "../QuestionTypes/ImageQuestion";
import OpenTextBox from "../QuestionTypes/OpenTextBox";
import NumericBulletSingleChoice from "../QuestionTypes/NumericBulletSingleChoice";
import Quantitative from "../QuestionTypes/Quantative";
import ImageAndText from "../QuestionTypes/ImageandText";
import TextImageWithQuestion from "../QuestionTypes/TextImageWithQuestion";
import LongText from "../QuestionTypes/LongText";
import { useSearchParams } from "react-router-dom";
import ModalComponent from "../ModalComponent/ModalComponent";
import { getCookie } from "views/common/cookieUtils";

type Props = {};

const SplitScreen = (props: Props) => {
  const { questions }: any = useAppSelector((state) => state.Questions);
  const [showResult, setShowResult] = useState<any>(false);
  const [showModal, setShowModal] = useState(false);
  const [showResultMode, setShowResultMode] = useState(false);
  const [searchParams, setSerchParams] = useSearchParams();
  const user_id = getCookie("id");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (user_id) {
      dispatch(
        FetchQuestions({
          user_id: user_id,
          container_id: searchParams.get("container_type_id"),
        })
      ).then((resp: any) => {
        if (resp.payload.data.length === resp.payload.question_data.length) {
          navigate(`/practice-summary?container_type_id=1`);
        }
      });
    }
  }, [user_id]);

  const handleNextCheckAnswer = () => {
    dispatch(
      GetAllSubmittedQuestion({
        user_id: user_id,
        container_id: searchParams.get("container_type_id"),
      })
    );
    if (showResultMode === false) {
      setShowResultMode(true);
      dispatch(updateActiveQuestion(1));
    }
    if (activeQuestion === questions.length - 1) {
      dispatch(setQuizQuestionComplete(true));
    } else {
      dispatch(updateActiveQuestion(activeQuestion + 1));
    }
    dispatch(setProgressBarFlag(true));
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const handleAnswerSubmit = (data: any) => {
    if (data.answer === undefined || data.answer === "") {
      setShowModal(true);
    } else {
      dispatch(
        SubmitQuestionsAns({
          user_id: data.user_id,
          container_id: data.container_id,
          question_id: data.question_id,
          collection_id: "",
          answer: data.answer,
          is_skipped_question: 0,
          notes: "",
          time_taken_for_answer: data.time_taken_for_answer,
        })
      ).then((res: any) => {
        if (activeQuestion + 1 === questions.length) {
          setShowResult(true);
        } else {
          dispatch(updateActiveQuestion(activeQuestion + 1));
          setShowResult(false);
        }
      });
    }
  };

  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );

  const currentQuestion = activeQuestion + 1;

  return (
    <>
      <div style={{ width: "724px", height: "406px" }}>
        <div>
          <div>
            {/* <Typography>
        <FormattedMessage id="examines-analogies" />
      </Typography> */}
            {/* <div>
              <div>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#878E95" }}
                >
                  <FormattedMessage id="practice-time" />
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 600, color: "black" }}
                >
                  <b>{container_data?.totalExamTime}</b>
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#878E95" }}
                >
                  <FormattedMessage id="practice-time" />

                </Typography>
              </div>
              <div>
                <ViewColumn />
              </div>
            </div> */}
          </div>
          <Typography
            className={`s `}
            style={{ color: "#878E95", fontWeight: 100 }}
          >
            {/* ss */}
            <FormattedMessage
              id="questionInfo"
              values={{ totalQuestions: questions.length, currentQuestion }}
            />
          </Typography>
          {questions.map((item: any, index: any) => {
            return (
              <>
                <div>
                  {item.type.id === 1 && activeQuestion === index && (
                    <>
                      <OpenTextBox
                        question={item.question}
                        onSubmit={handleAnswerSubmit}
                        question_id={item.id}
                        timesPerQuestion={item.time_per_question}
                        showResultMode={showResultMode}
                        solving_explanation={item.solving_explanation}
                      />
                    </>
                  )}
                  {item.type.id === 2 && activeQuestion === index && (
                    <>
                      <ImageAndText
                        question_id={item.id}
                        question={item.question}
                        option_images={item.option_images}
                        options={item.options}
                        image={item.image}
                        onSubmit={handleAnswerSubmit}
                        timesPerQuestion={item.time_per_question}
                        collection={item.collections}
                        is_completed={item.is_completed}
                        is_skipped_question={item.is_skipped_question}
                        solving_explanation={item.solving_explanation}
                        showResultMode={showResultMode}
                      />
                    </>
                  )}
                  {item.type.id === 3 && activeQuestion === index && (
                    <>
                      <TextImageWithQuestion
                        question={item.question}
                        option_images={item.option_images}
                        options={item.options}
                        image={item.image}
                        question_id={item.id}
                        onSubmit={handleAnswerSubmit}
                        timesPerQuestion={item.time_per_question}
                        collection={item.collections}
                        solving_explanation={item.solving_explanation}
                        showResultMode={showResultMode}
                      />
                    </>
                  )}
                  {item.type.id === 4 && activeQuestion === index && (
                    <>
                      <NumericBulletSingleChoice
                        question={item.question}
                        option_images={item.option_images}
                        options={item.options}
                        image={item.image}
                        question_id={item.id}
                        onSubmit={handleAnswerSubmit}
                        timesPerQuestion={item.time_per_question}
                        collection={item.collections}
                        solving_explanation={item.solving_explanation}
                        showResultMode={showResultMode}
                        explanation_image={item.explanation_image}
                      />
                    </>
                  )}
                  {item.type.id === 5 && activeQuestion === index && (
                    <LongText />
                  )}
                  {item.type.id === 6 && activeQuestion === index && (
                    <ImageQuestion />
                  )}
                  {item.type.id === 7 && activeQuestion === index && (
                    <>
                      <EnglishLayout
                        question={item.question}
                        option_images={item.option_images}
                        options={item.options}
                        image={item.image}
                        question_id={item.id}
                        onSubmit={handleAnswerSubmit}
                        timesPerQuestion={item.time_per_question}
                        collection={item.collections}
                        solving_explanation={item.solving_explanation}
                        showResultMode={showResultMode}
                      />
                    </>
                  )}
                  {item.type.id === 8 && activeQuestion === index && (
                    <Quantitative />
                  )}
                  {item.type.id === 9 && activeQuestion === index && (
                    <VerbatimText />
                  )}
                  {item.type.id === 10 && activeQuestion === index && (
                    <>
                      <EnglishLayout
                        question={item.question}
                        option_images={item.option_images}
                        options={item.options}
                        image={item.image}
                        question_id={item.id}
                        onSubmit={handleAnswerSubmit}
                        timesPerQuestion={item.time_per_question}
                        collection={item.collections}
                        solving_explanation={item.solving_explanation}
                        showResultMode={showResultMode}
                      />
                    </>
                  )}

                  {showResultMode && activeQuestion === index ? (
                    <DescriptionBox description={item.solving_explanation} />
                  ) : (
                    <></>
                  )}
                </div>
              </>
            );
          })}
          {showResult ? (
            <>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "7A49B9",
                  my: "15px",
                  width: "152px",
                  height: "40px",
                  padding: "9px 22px",
                  borderRadius: "8px",
                }}
                onClick={() => handleNextCheckAnswer()}
              >
                <FormattedMessage id="next-question" />
              </Button>
              <Button
                variant="contained"
                sx={{
                  my: "15px",
                  marginRight: "10px",
                  height: "40px",
                  padding: "10px 30px",
                  borderRadius: "8px",
                }}
              >
                <FormattedMessage id="done-researching" />
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <QuestionSideBar question_id={questions[activeQuestion]} />
      {showModal && <ModalComponent isDialogOpen={true} onClose={closeModal} />}
    </>
  );
};

export default SplitScreen;
