import { useEffect } from "react";

export const useWiris = (isLoading: boolean) => {
  useEffect(() => {
    const script = document.createElement("script");
    if (!isLoading) {
      script.src =
        "https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image";
      document.head.appendChild(script);
    }

    return () => {
      script.remove();
    };
  }, [isLoading]);
};

export const useWirisParser = (refs: any) => {
  useEffect(() => {
    if ((window as any).com) {
      if (refs.current) {
        (window as any).com.wiris.js.JsPluginViewer.parseElement(
          refs.current,
          true,
          function () {}
        );
      }
    }
  }, [refs]);
};
