// src/utils/cookieUtils.ts

export const setCookie = (
  name: string,
  value: any,
  duration: number,
  unit: "m" | "h"
): void => {
  let expires = "";
  let milliseconds = 0;

  // Calculate milliseconds based on the unit
  if (unit === "h") {
    milliseconds = duration * 60 * 60 * 1000; // Convert hours to milliseconds
  } else if (unit === "m") {
    milliseconds = duration * 60 * 1000; // Convert minutes to milliseconds
  }

  const date = new Date();
  date.setTime(date.getTime() + milliseconds);
  expires = "; expires=" + date.toUTCString();

  document.cookie = name + "=" + (value || "") + expires + "; path=/";

  setTimeout(() => {
    clearCookie(name);
  }, milliseconds);
};

export const getCookie = (name: string) => {
  const decodedCookies = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookies.split("; ");
  const cookie = cookieArray.find((c) => c.startsWith(`${name}=`));
  return cookie ? cookie.split("=")[1] : undefined;
};

export const removeAllCookies = (): void => {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // window.location.reload();
  }
};

export const clearCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
};
