import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";

interface onboardingInfo {
  student_id: string | null;
  steps_done: number;
  studentData: any;
  product_category_id?: string;
}
interface studentNotes {
  student_id: string | null;
  note: string;
}
interface fetchOnboardingInfoData {
  student_id?: string | null;
  course_id?: string;
  product_category_id?: string;
}

// export const FetchOnboardingInfo = createAsyncThunk(
//   "BoardingSlice/getStudentsBasedOnCourse",
//   async (data: any) => {
//     const res = await axiosClient.get(
//       `get-onboarding-info?student_id=${data.student_id}&course_id=${data.course_id}`
//     );
//     return res.data;
//   }
// );
export const FetchOnboardingInfo = createAsyncThunk(
  "BoardingSlice/getStudentsBasedOnCourse",
  async (data: fetchOnboardingInfoData) => {
    try {
      let url = "get-onboarding-info?";

      if (data.student_id) {
        url += `student_id=${data.student_id}`;
      }

      if (data.course_id) {
        if (data.student_id) {
          url += `&`;
        }
        url += `course_id=${data.course_id}`;
      }
      if (data.product_category_id) {
        if (data.product_category_id) {
          url += `&`;
        }
        url += `product_category_id=${data.product_category_id}`;
      }

      const res = await axiosClient.get(url);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const SubmitOnBoardingInfo = createAsyncThunk(
  "BoardingSlice/saveOnBoardingInfo",
  async (data: onboardingInfo) => {
    try {
      const res = await axiosClient.post("save-onboarding-info", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const SaveStudentNotes = createAsyncThunk(
  "BoardingSlice/saveStudentNotes",
  async (data: studentNotes) => {
    try {
      const res = await axiosClient.post("save-student-notes", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

interface initialStage {
  personalDetails: any[];
  isLoading: boolean;
  error: any;
}

const initialState: initialStage = {
  personalDetails: [],
  isLoading: false,
  error: null,
};

const BoardingSlice = createSlice({
  name: "BoardingSlice",
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(FetchOnboardingInfo.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchOnboardingInfo.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          personalDetails: action.payload.data,
        };
      })
      .addCase(FetchOnboardingInfo.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(SubmitOnBoardingInfo.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(SubmitOnBoardingInfo.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(SubmitOnBoardingInfo.rejected, (state, action) => {
        return {
          ...state,
          error: action.error.message,
          isLoading: false,
        };
      })
      .addCase(SaveStudentNotes.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(SaveStudentNotes.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(SaveStudentNotes.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      });
  },
});

export default BoardingSlice.reducer;
