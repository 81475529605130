import React, { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import Header from "views/student/Header";
import Footer from "../views/common/Footer";
import LoadingFallback from "views/common/LoadingFallback";

// Lazy load components
const StudentLibrary = lazy(
  () => import("views/student-library/StudentLibrary")
);
const TopicDetailList = lazy(
  () => import("views/student-library/TopicDetailList")
);
const LibraryTagWiseFresult = lazy(
  () => import("views/student-library/LibraryTagWiseFresult")
);
const LibraryTagWiseLeafPage = lazy(
  () => import("views/student-library/LibraryTagWiseLeafPage")
);
const LibraryTagWiseFresultTeacher = lazy(
  () => import("views/library/LibraryTagWiseFresultTeacher")
);
const TeacherLibraryProductWise = lazy(
  () => import("views/library/TeacherLibraryProductWise")
);
const TeacherLibrary = lazy(() => import("views/library/TeacherLibrary"));
const TeacherTopicDetailList = lazy(
  () => import("views/library/TeacherTopicDetailList")
);
const TeacherLibraryTagWiseLeafPage = lazy(
  () => import("views/library/TeacherLibraryTagWiseLeafPage")
);

const LibraryRoutes = {
  path: "/",
  element: (
    <>
      <Outlet />
    </>
  ),
  children: [
    {
      path: "/home/library/product-page",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Library">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <TeacherLibraryProductWise />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/library",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Library">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <TeacherLibrary />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/student/library",
      element: (
        <ProtectedRoute user_type={"Student"} title="Library">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <StudentLibrary />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/library/:subject",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Library">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <TeacherTopicDetailList />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/student/library/:subject",
      element: (
        <ProtectedRoute user_type={"Student"} title="Library">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <TopicDetailList />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/library/firstResult/:selectedSearch/:tagId",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Library">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <LibraryTagWiseFresultTeacher />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/student/library/firstResult/:selectedSearch/:tagId",
      element: (
        <ProtectedRoute user_type={"Student"} title="Library">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <LibraryTagWiseFresult />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/library/leafPage/:selectedSearch/:tagId",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Library">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <TeacherLibraryTagWiseLeafPage />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/student/library/leafPage/:selectedSearch/:tagId",
      element: (
        <ProtectedRoute user_type={"Student"} title="Library">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <LibraryTagWiseLeafPage />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
  ],
};

export default LibraryRoutes;
