export const subtractTimes =(time1:string, time2:string)=>{
    const [minutes1, seconds1] = time1.split(':').map(Number);
    const [minutes2, seconds2] = time2.split(':').map(Number);
  
    let totalSeconds1 = minutes1 * 60 + seconds1;
    let totalSeconds2 = minutes2 * 60 + seconds2;
  
    let diffSeconds = totalSeconds1 - totalSeconds2;
    if (diffSeconds < 0) {
      diffSeconds = 0;
    }
  
    const diffMinutes = Math.floor(diffSeconds / 60);
    const remainingSeconds = diffSeconds % 60;
  
    const formattedDiff = `${diffMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    
    
    return formattedDiff;
  }