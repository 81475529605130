import React, { useState, useEffect } from 'react';

// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl';

// load locales files
const loadLocaleData = (locale: string) => {
    switch (locale) {
        case 'he':
            return import('./he.json');
        default:
            return import('./en.json');
    }
};

export interface LocalsProps {
    children: React.ReactNode;
}

const Locales = ({ children }: LocalsProps) => {
    const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();

    useEffect(() => {
        loadLocaleData('he').then(
            (d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
                setMessages(d.default);
            }
        );
    }, []);

    return (
        <>
            {messages && (
                <IntlProvider locale="he" defaultLocale="he" messages={messages}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

export default Locales;