import {
  Button,
  TextField,
  InputLabel,
  Box,
  Typography,
  Alert,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  FetchCheckUserLogin,
  FetchSendOtp,
  updateWorkerId,
} from "Redux/feature/UserAuth";
import { useEffect, useState } from "react";

const VerifyUserId = () => {
  const [workerId, setWorkerId] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showAlert, setShowAlert] = useState(false);

  const responseMessage: any = useAppSelector(
    (state) => state.UserAuth.message
  );
  const handleSubmit = () => {
    dispatch(FetchCheckUserLogin({ workerId: workerId }))
      .then((e) => {
        if (e.payload !== undefined) {
          if (e.payload?.data?.is_first_login === false) {
            navigate("/");
          } else {
            dispatch(FetchSendOtp({ username: workerId })).then((e) => {
              e.payload.status && navigate("/verifyotp");
            });
          }
        } else {
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 4000);
        }
      })
      .catch((error) => {
        console.error("Error during dispatch:", error);
      });
  };

  useEffect(() => {
    try {
      dispatch(updateWorkerId(workerId));
    } catch (error) {
      console.error("Error updating worker ID:", error);
    }
  }, [workerId]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        width={{ xs: "100%", sm: "394px" }}
        sx={{
          mx: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: "22px",
          pb: "40px",
        }}
      >
        <Typography
          sx={{ fontSize: "32px", fontWeight: 700 }}
          className="login-title"
        >
          <FormattedMessage id="login-title" />
        </Typography>
        <Box sx={{ mt: 1, width: "100%" }}>
          <Typography
            variant="subtitle1"
            textAlign={{ xs: "center", md: "right" }}
          >
            <FormattedMessage id="sample-text" />
          </Typography>
          <InputLabel
            htmlFor="userId"
            sx={{ marginTop: "40px", marginBottom: "5px" }}
          >
            <FormattedMessage id="userName" />
          </InputLabel>
          <TextField
            margin="none"
            required
            fullWidth
            id="userId"
            name="userId"
            value={workerId}
            onChange={(e) => {
              setWorkerId(e.target.value);
            }}
            type="text"
            size="small"
            sx={{ fontSize: "16px", height: "38px" }}
          />
          {showAlert && (
            <Alert
              className="removeIconLessonBooksAlert"
              sx={{
                width: "auto",
                height: "auto",
                color: "red",
                fontWeight: 600,
                fontSize: "16px",
                padding: "0px",

                backgroundColor: "transparent",
              }}
            >
              {responseMessage}
            </Alert>
          )}
          <Button
            fullWidth
            variant="contained"
            disabled={workerId === ""}
            sx={{ marginTop: "40px", height: "40px", fontSize: "18px" }}
            onClick={handleSubmit}
          >
            <FormattedMessage id="send-verification-code" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default VerifyUserId;
